import React from 'react';
import{ useSortBy, useTable, useFilters } from 'react-table';
import '../styles/Table.css';
import { IconButton, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { FirstPage, NavigateBefore, NavigateNext, LastPage } from '@mui/icons-material';

const comparisons = {
  1: 'same',
  2: 'different',
  3: 'missing',
  4: 'missing'
};

function Table({ columns, hiddenColumns = ['COMPARISON','ROWNUMBER',], data, pageIndex = 1, pageSize, pageCount = 0, separatorColumn, displaySort = '', sortDirection = 'ASC', selectedRows, selectRow, selectAllRows, updatePageIndex, updatePageSize, updateDisplaySort }) {

  const {
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    prepareRow, 
    rows, 
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns
      },
      // filterTypes,
    },
    useFilters,
    useSortBy,
  );

  return (
    <>
      <div className='table-container'>
        <table {...getTableProps()} border="1">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  selectedRows ? 
                  <th>
                    {
                      headerGroup.headers.findIndex((column) => {
                        if (column.Header.toString() === 'EWFM' || column.Header.toString() === 'Connect') {
                          return true;
                        }
                        else {
                          return false;
                        }
                      }) > -1 ? 
                      undefined : 
                        <input 
                          type="checkbox" 
                          ref={(input) => { 
                            if (input) { 
                              input.indeterminate = (selectedRows && selectedRows.length > 0 && selectedRows.length < data.length);
                            }
                          }}
                          checked={selectedRows && selectedRows.length === rows.length} 
                          onChange={() => selectAllRows()} 
                        />
                    }
                  </th> : undefined
                }
                {headerGroup.headers.map((column) => (
                  <th 
                    {...column.getHeaderProps([
                      column.id === separatorColumn || column.Header === 'Connect' ? { className: 'connect-border' } : {},
                      { onClick: () => updateDisplaySort(column.id, column.Header) },
                    ])}
                  >
                    {column.Header} {column.id === displaySort ? (sortDirection === 'DESC' ? '▼': '▲') : ''}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr 
                  {...row.getRowProps([
                    row.values.COMPARISON ? { className: comparisons[row.values.COMPARISON] + '-row' } : {},
                  ])}
                >
                  {
                    selectedRows ?
                    <td>
                      <input type="checkbox" checked={selectedRows ? (selectedRows.indexOf(row.values.ROWNUMBER) !== -1) : false} onChange={(e) => selectRow(row.values.ROWNUMBER)} />
                    </td> :
                    undefined
                  }
                  {row.cells.map((cell) => {
                    var hierarcyString = `EWFM Hierarcy: ${cell.row.values.EWFM_HIERARCHY_HASH}, \r\nConnect Hierarchy: ${cell.row.values.CONNECT_HIERARCHY_HASH}`;
                    return (
                    <td 
                      {...cell.getCellProps([
                        cell.column.id === separatorColumn ? { className: 'connect-border' } : {},
                        {
                          title: cell.row.values.EWFM_USERNAME || row.values.CONNECT_USERNAME ? hierarcyString : ""
                        }
                      ])} 
                    >
                      {cell.render("Cell")}
                    </td>);
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className='pagnation'>
          <div className='page-buttons-container'>
            <IconButton onClick={() => updatePageIndex(1)} disabled={!(pageIndex && (pageIndex > 1))} className='page-button'>
              <FirstPage />
            </IconButton>
            <IconButton onClick={() => updatePageIndex(pageIndex - 1)} disabled={!(pageIndex && (pageIndex > 1))} className='page-button'>
              <NavigateBefore />
            </IconButton>
            <IconButton onClick={() => updatePageIndex(pageIndex + 1)} disabled={!(pageIndex && (pageIndex < pageCount))} className='page-button'>
              <NavigateNext/>
            </IconButton>
            <IconButton onClick={() => updatePageIndex(pageCount)} disabled={!(pageIndex && (pageIndex < pageCount))} className='page-button'>
              <LastPage />
            </IconButton>
          </div>
          <span>
            Page{' '}
            <strong>
              <FormControl size="small" variant="standard" >
                <Select
                  id="page-index-select"
                  value={pageIndex ? pageIndex : 1}
                  onChange={e => updatePageIndex(Number(e.target.value))}
                  disabled={!pageIndex}
                >
                  {
                    (
                      () => {
                        var menuItems = [];
                        for(var i = 1; i <= pageCount; i++){
                          menuItems.push(
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          )
                        }
                        return menuItems;
                      }
                    )()
                  }
                </Select>
              </FormControl> of {pageCount}
            </strong>{' '}
          </span>
          <FormControl size="small" variant="standard" >
            <InputLabel id="page-size-select-label">Page Size</InputLabel>
            <Select
              labelId="page-size-select-label"
              style={{"minWidth": "80px"}}
              value={pageSize ? pageSize : 50}
              onChange={e => updatePageSize(Number(e.target.value))}
              id="page-size-select"
              disabled={!pageSize}
              label="Page Size"
            >
              {[5, 10, 50].map(pageSize => (
                <MenuItem key={pageSize} value={pageSize}>
                  {pageSize}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  )
}

export default Table;