import {useState, useEffect} from 'react';
import axios from 'axios';
import { MenuItem, Select, FormControl, InputLabel, ListItemText, Checkbox, Tooltip, Button, CircularProgress } from '@mui/material';
import Table from './Table.js';
import { URLS, API_KEYS } from '../configs/apis.js';
import '../styles/Filters.css';

export default function RoutingProfiles () {
  const [comparisonCounts, setComparisonCounts] = useState({
    'All': 0,
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
  });

  const [data, setData] = useState([{
        'ROWNUMBER': '-1',
        'EWFM_ROUTING_PROFILE': '-',
        'CONNECT_ROUTING_PROFILE': '-',
        'COMPARISON': '-'
  }]);
  const columns = [
    {
      Header: 'EWFM',
      accessor: '',
      columns: [
        {
          Header: 'Routing Profile',
          accessor: 'EWFM_ROUTING_PROFILE',
        },
      ]
    },
    {
      Header: 'Connect',
      accessor: '',
      columns: [
        {
          Header: 'Routing Profile',
          accessor: 'CONNECT_ROUTING_PROFILE',
        },
      ]
    },
    {
      Header: 'Comparison',
      accessor: 'COMPARISON',
    },
    {
      Header: 'Row Number',
      accessor: 'ROWNUMBER',
    },
  ];
  const [displaySort, setDisplaySort] = useState('EWFM_ROUTING_PROFILE');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const comparisons = [
    { id: '1', text: 'Matching in EWFM and Connect', count: comparisonCounts && comparisonCounts['1'] ? comparisonCounts['1'] : '0'},
    { id: '3', text: 'Exist in Connect not in EWFM', count: comparisonCounts && comparisonCounts['3'] ? comparisonCounts['3'] : '0'},
    { id: '4', text: 'Exist in EWFM not in Connect', count: comparisonCounts && comparisonCounts['4'] ? comparisonCounts['4'] : '0'}
  ];

  const [selectedComparisons, setSelectedComparisons] = useState([]);

  const [gettingExport, setGettingExport] = useState(false);

  /**
   * Called once on first render to load initial data
   */
   useEffect(() => {
    loadData(1, 50, 'EWFM_ROUTING_PROFILE', 'ASC');
  }, []);

  /**
   * Gets all routing profiles in Connect and EWFM
   * @param {*} pgIndex 
   * @param {*} pgSize 
   * @param {*} dispSort 
   * @param {*} srtDirection 
   * @param {*} comps 
   */
  const loadData = async (pgIndex = pageIndex, pgSize = pageSize, dispSort = displaySort, srtDirection = sortDirection, comps = selectedComparisons) => {
    console.log(`loading routing profile data with pageIndex:${pgIndex}, pageSize: ${pgSize}, displaySort: ${dispSort}, sortDirection: ${srtDirection}, comparisons: ${comps}`);

    //If no comparisons selected then set the filter
    let stringComparisons = 'All';
    if(comps.length > 0 && comps.length < comparisons.length)
      stringComparisons = comps.join(',') || 'All';

    setLoadingData(true);

    const config = {
      headers: {
        'x-api-key': API_KEYS.LOAD_ROUTING_PROFILES_KEY,
      }
    };

    pgIndex = Math.round((pgSize * (pgIndex - 1)) + 1);

    const URL = URLS.LOAD_ROUTING_PROFILES_URL + 
      `?startIndex=${encodeURIComponent(pgIndex)}&pageSize=${encodeURIComponent(pgSize)}` + 
      `&displayFilter=${encodeURIComponent(stringComparisons)}&displaySort=${dispSort}` + 
      `&displaySortDirection=${srtDirection}`;

    console.log('Now calling URL: ' + URL);
    axios.get(URL, config)
    .then((response) => {
      console.log(response);

      setLoadingData(false);

      let data = response && response.data && response.data.dataSet ? response.data.dataSet : [];
      console.log('Data: ' + JSON.stringify(data));

      let totalsArray = response && response.data && response.data.totals ? response.data.totals : [];
      let compCounts = {
        'All': 0,
        '1': 0,
        '2': 0,
        '3': 0,
        '4': 0,
      };

      console.log('Now looping through counts');
      totalsArray.forEach((total, i) => {
        compCounts[total.COMPARISON] = total.count;
        compCounts.All += total.count;
      });

      console.log('comparisonCounts: ' + JSON.stringify(compCounts));

      let pgCount = 0;
      let tlRows = 0;
      if(comps.length === 0 || comps.length === comparisons.length) {
        tlRows = compCounts.All;
      }
      else {
        console.log('getting page count for comps ' + JSON.stringify(comps));
        for(var i = 0; i < comps.length; i++) {
          console.log('Adding count for ' + comps[i]);
          tlRows += compCounts[comps[i]];
        }
      }

      pgCount = Math.ceil(tlRows / pgSize);

      //If data is 0 then don't leave empty column
      if(data.length === 0) {
        data = [{
          'ROWNUMBER': '-1',
          'EWFM_ROUTING_PROFILE': '-',
          'CONNECT_ROUTING_PROFILE': '-',
          'COMPARISON': '-'
        }];
        pgCount = 0;
      }

      console.log('pageCount: ' + JSON.stringify(pgCount));

      setData(data);
      setPageCount(pgCount);
      setTotalRows(tlRows)
      setComparisonCounts(compCounts);
    })
    .catch((err) => {
      console.log(err);
      setLoadingData(false);
    });
  }

  const onComparisonChanged = async (values) => {
    console.log(`Comparision changed to ${values}`);

    if(values.indexOf("All") > -1 && selectedComparisons.length < comparisons.length) {
      console.log("Setting comparisons to include all");
      setSelectedComparisons(comparisons.map(comp => comp.id));
      values = ["All"];
    }
    else if(values.indexOf("All") > -1 || values.length === 0) {
      console.log("Setting comparisons to []");
      setSelectedComparisons([]);
      values = ["All"];
    }
    else {
      console.log("Adding comparision " + JSON.stringify(values));
      setSelectedComparisons(values);
    }

    //Set page index to 1 to avoid going over max page number
    setPageIndex(1);

    //load data with new comparison values
    loadData(1, pageSize, displaySort, sortDirection, values);
  }

  const updatePageIndex = async (newPageIndex) => {
    setPageIndex(newPageIndex);
    loadData(newPageIndex);
  }

  const updatePageSize = async (newPageSize) => {
    console.log('Called updatePageSize with newPageSize: ' + newPageSize.toString());
    let newPageIndex = Math.floor((pageSize / newPageSize) * pageIndex);
    let newPageCount =  Math.ceil(totalRows / newPageSize);
    if (newPageIndex < 1) newPageIndex = 1
    else if(newPageIndex > newPageCount) newPageIndex = newPageCount;

    setPageSize(newPageSize);
    setPageIndex(newPageIndex);
    setPageCount(newPageCount);

    console.log(`Updated page size to ${newPageSize}, pageCount to ${newPageCount} and pageIndex to ${newPageIndex}`)
    loadData(newPageIndex, newPageSize);
  }

  const updateDisplaySort = async (dispSort, dispHeader) => {
    console.log('Sorting by ' + dispSort + ' display header: ' + dispHeader);
    //If the header blocks were clicked don't try and sort on them
    if(!dispSort || dispHeader === 'EWFM' || dispHeader === 'Connect')
      return;

    let srtDirection = 'DESC';
    if (sortDirection === 'DESC') {
      srtDirection = 'ASC';
    }
    else {
      srtDirection= 'DESC';
    }

    setDisplaySort(dispSort);
    setSortDirection(srtDirection);

    loadData(pageIndex, pageSize, dispSort, srtDirection);
  }

  const getExportUrl = async () => {
    if(!gettingExport) {
      setGettingExport(true);

      const config = {
        headers: {
          'x-api-key': API_KEYS.EXPORT_KEY,
        }
      };

      const URL = URLS.EXPORT_URL + 
      '?exportMode=RoutingProfiles';

      console.log('Now calling URL: ' + URL);
      axios.get(URL, config)
      .then((response) => {
        console.log("RESPONSE FROM EXPORT " + JSON.stringify(response));
        setGettingExport(false);
        var {success, url, message} = response ? response.data: null;
        if(success)
          window.location.assign(url);
        else
          window.alert(`Error getting extract\r\n${message}`);
      });

      console.log("Getting export");
    }
    else {
      console.log("Already getting export");
    }
  }

  /**
   * Returns the text to be displayed in the comparison select box
   * @param {*} selectedValues 
   * @returns 
   */
  const renderComparisonValues = (selectedValues) => {
    if(selectedValues.length === 1 && selectedValues.indexOf('All') === -1) {
      //Only one selection and not 'All' so display value
      return comparisons.filter((comp) => comp.id === selectedValues[0])[0].text;
    }
    else if((selectedValues.indexOf("All") > -1 && selectedComparisons.length < comparisons.length) || 
      (selectedValues.indexOf("All") === -1 && selectedComparisons.length === comparisons.length) || 
      selectedValues.length === 0) {
      return "All";
    }
    else {
      return "Custom";
    }
  }

  return (
    <>
      <div className={'hierarchy-container' + (loadingData ? ' loading' : '')}>
        <div className='filters-container'>
          <div className='filters-row'>
            <div className='select-container'>
              <FormControl fullWidth={true} variant='standard' >
                <InputLabel id='comparisons-label'>Filter: </InputLabel>
                <Select
                  labelId='comparisons-label'
                  id='comparisons'
                  name='comparisons'
                  multiple
                  value={selectedComparisons}
                  onChange={(e) => onComparisonChanged(e.target.value)}
                  renderValue={(selected) => renderComparisonValues(selected)}
                  label='Filter: '
                >
                  <MenuItem key='-1' value='All'>
                    <Checkbox checked={(selectedComparisons.length === comparisons.length) || selectedComparisons.length === 0} />
                    <ListItemText primary='All' secondary={comparisonCounts && comparisonCounts['All'] ? comparisonCounts['All'] : '0'} />
                  </MenuItem>
                  {
                    comparisons.map((comparison, i) => (
                      <MenuItem key={i} value={comparison.id}>
                        <Checkbox checked={(selectedComparisons.findIndex(selectedComparison => selectedComparison === comparison.id) > -1) || (selectedComparisons[0] === 'All')} />
                        <ListItemText primary={comparison.text} secondary={comparison.count} />
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className="export-container">
              <Tooltip 
                title="Export routing profiles as a .csv file." 
                describeChild 
                placement="right" 
              >
                <Button
                    variant="contained"
                    id="export-button" onClick={() => getExportUrl()}
                    className="export-button"
                    disabled={gettingExport}
                    disableElevation
                >
                  Export Routing Profiles
                </Button>
              </Tooltip>
              {
                gettingExport ?
                <CircularProgress size={25} /> :
                null
              }
            </div>
          </div>
        </div>
        <Table 
          columns={columns} 
          data={data} 
          pageSize={pageSize} 
          pageIndex={pageIndex} 
          pageCount={pageCount} 
          separatorColumn='CONNECT_ROUTING_PROFILE' 
          displaySort={displaySort} 
          sortDirection={sortDirection} 
          updatePageIndex={(newPageIndex) => updatePageIndex(newPageIndex)} 
          updatePageSize={(newPageSize) => updatePageSize(newPageSize)} 
          updateDisplaySort={(displaySort, displayHeader) => updateDisplaySort(displaySort, displayHeader)} 
        />
      </div>
    </>
  );
}
