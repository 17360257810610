import React from 'react';
import{ useTable, } from 'react-table';
import axios from 'axios';
import { URLS, API_KEYS } from '../configs/apis.js';
import '../styles/About.css';


export default class AgentHierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          Header: 'Resource',
          accessor: 'RESOURCE',
        },
        {
          Header: 'Version',
          accessor: 'VERSION',
        },
        {
          Header: 'Date',
          accessor: 'DATE',
        },
      ],
      data: [{
        "RESOURCE": "-",
        "VERSION": "-",
        "DATE": "-",
      }],
      displaySort: "",
      sortDirection: "",
      pageIndex: 1,
      pageCount: 0,
    };
  }

  render() {
    return (
      <div className={'hierarchy-container' + (this.state.loadingData ? ' loading' : '')}>
        <div>
          <h1>Config Sync</h1>
          <p>
            This site is used to sync users from Via/eWFM to Connect.
          </p>
        </div>
        <div>
          <h2>Agents Tab</h2>
          <p>This tab displays all the agents with their details from eWFM and Connect hightlighting them in yellow and red when they don't match or don't exist in one of the two. On hover it will also display an
            agent's hierarchy in both. The filters will filter all agent's that match the given filter. The Connect filters on this tab filter on agent's actual business unit in the portal and 
            assigned routing profile, not on the business unit and routing profile in their hierarchy.
          </p>
          <p>
            The LOAD buttons will load fresh data from Connect and eWFM.
          </p>
          <p>
            The export button will export a .csv file containing all agents which match the currently selected filters.
          </p>
          <p>
            Sync All and Sync Selected will update the agents in Connect to match the details from eWFM. Sync All will update all users that match the currently selected filters.
          </p>
        </div>
        <div>
          <h2>
            Hierarchy Tab
          </h2>
          <p>
            This tab displays all the hierarchies that exist in eWFM and Connect.
          </p>
        </div>
        <div>
          <h2>
            Routing Profiles Tab
          </h2>
          <p>
            The tab displays the routing profiles that exist in eWFM and Connect. The button will download a csv containing a list of all the routing profiles in Connect.
          </p>
        </div>
        <div>
          <h2>
            Whitelisted Users
          </h2>
          <p>
            This tab manages users in the whitelist table. It decides which users should have access to Config Sync and which users should be excluded from the sync. 
          </p>
        </div>
      </div>
    );
  }
}

