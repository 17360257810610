import React from 'react';
import axios from 'axios';
import { Button, Tooltip, CircularProgress, LinearProgress, FormGroup, FormControlLabel, Switch } from '@mui/material';
import Table from './Table.js';
import Filters from './Filters.js';
import moment from 'moment';
import { API_KEYS, URLS, API_BODY } from '../configs/apis.js';


export default class AgentHierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{
        "ROWNUMBER": "-1",
        "LANID": "-",
        "EWFM_USERNAME": "-",
        "EWFM_FIRST_NAME": "-",
        "EWFM_LAST_NAME": "-",
        "EWFM_BUSINESS_UNIT": "-",
        "EWFM_ROUTING_PROFILE": "-",
        "EWFM_NOVAOS": "-",
        "EWFM_HIERARCHY_HASH": "-",
        "CONNECT_ID": "-",
        "CONNECT_USERNAME": "-",
        "CONNECT_EMAIL": "-",
        "CONNECT_FIRST_NAME": "-",
        "CONNECT_LAST_NAME": "-",
        "CONNECT_ROUTING_PROFILE": "-",
        "CONNECT_BUSINESS_UNIT": "-",
        "CONNECT_ELIGIBLE_FOR_SURVEY": "-",
        "CONNECT_HIERARCHY_HASH": "-",
        "COMPARISON": "-",
        "PRESERVE_USER_FLAG": "-",
      }],
      columns: [
        {
          Header: 'EWFM',
          accessor: '',
          columns: [
            {
              Header: 'Username',
              accessor: 'EWFM_USERNAME',
            },
            {
              Header: 'First Name',
              accessor: 'EWFM_FIRST_NAME',
            },
            {
              Header: 'Last Name',
              accessor: 'EWFM_LAST_NAME',
            },
            {
              Header: 'Business Unit',
              accessor: 'EWFM_BUSINESS_UNIT',
            },
            {
              Header: 'Routing Profile',
              accessor: 'EWFM_ROUTING_PROFILE',
            },
            {
              Header: 'NOVAOS',
              accessor: 'EWFM_NOVAOS',
            },
            {
              Header: 'Auto Accept',
              accessor: 'EWFM_AUTO_ACCEPT',
            },
            {
              Header: 'Quality Analyst',
              accessor: 'EWFM_QUALITY_ANALYST',
            },
            {
              Header: 'Hierarcy',
              accessor: 'EWFM_HIERARCHY_HASH',
            },
          ]
        },
        {
          Header: 'Connect',
          accessor: '',
          columns: [
            {
              Header: 'Username',
              accessor: 'CONNECT_USERNAME',
            },
            {
              Header: 'First Name',
              accessor: 'CONNECT_FIRST_NAME',
            },
            {
              Header: 'Last Name',
              accessor: 'CONNECT_LAST_NAME',
            },
            {
              Header: 'Business Unit',
              accessor: 'CONNECT_BUSINESS_UNIT',
            },
            {
              Header: 'Routing Profile',
              accessor: 'CONNECT_ROUTING_PROFILE',
            },
            {
              Header: 'Eligible for Survey',
              accessor: 'CONNECT_ELIGIBLE_FOR_SURVEY',
            },
            {
              Header: 'Auto Accept',
              accessor: 'CONNECT_AUTO_ACCEPT',
            },
            {
              Header: 'Quality Analyst',
              accessor: 'CONNECT_QUALITY_ANALYST',
            },
            {
              Header: 'Hierarcy',
              accessor: 'CONNECT_HIERARCHY_HASH',
            },
          ]
        },
        {
          Header: 'Comparison',
          accessor: 'COMPARISON',
        },
        {
          Header: 'Row Number',
          accessor: 'ROWNUMBER',
        },
      ],
      hiddenColumns: [
        'COMPARISON',
        'ROWNUMBER',
        'EWFM_HIERARCHY_HASH',
        'CONNECT_HIERARCHY_HASH',
      ],
      businessUnit: 'All', 
      routingProfile: 'All', 
      connectBusinessUnit: 'All', 
      connectRoutingProfile: 'All', 
      businessUnits: [],
      routingProfiles: [],
      connectBusinessUnits: [],
      connectRoutingProfiles: [],
      comparison: "All", 
      displaySort: "EWFM_USERNAME", 
      selectedRows: [],
      sortDirection: 'ASC',
      pageIndex: 1,
      pageSize: 50,
      pageCount: 0,
      comparisonCounts: {
        "All": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
      },
      syncStatus: '',
      syncPercentage: 0,
      syncRunBy: '',
      syncLastRunTime: '',
      loadingData: false,
      gettingExport: false,
      excludeWhitelistUsers: 0,
      totalRows: 0,
    };
  }

  /**
   * Called after render
   */
  componentDidMount() {
    this.loadBusinessUnitsAndRoutingProfiles();
    this.loadData();
    this.initialListSyncs();
  }

  /**
   * Adds the row numbers for all displayed data to selected rows array on the state
   */
  selectAllRows() {
    console.log('selectAllRows called');
    var selectedRows = [];

    if(this.state.selectedRows.length < this.state.data.length) {
      selectedRows = this.state.data.map((dataRow) => {
        return dataRow.ROWNUMBER;
      });
    }

    console.log('selectedRows: ' + JSON.stringify(selectedRows, null, 2));
    this.setState({selectedRows});
  }

  /**
   * Adds the given row number to the selectedRows array on the state or removes if already there
   * @param {*} rowNumber 
   */
  selectRow(rowNumber) {
    console.log('Selected row ' + rowNumber);

    var selectedRows = this.state.selectedRows;
    var index = selectedRows.indexOf(rowNumber);

    if(index === -1) {
      selectedRows.push(rowNumber);
    }
    else {
      selectedRows.splice(index, 1);
    }

    console.log('selectedRows: ' + JSON.stringify(selectedRows, null, 2));
    this.setState({selectedRows});
  }

  /**
   * Sets the state for the given filters awaiting the change and then calling loadData
   * @param {*} businessUnit 
   * @param {*} routingProfile 
   * @param {*} connectBusinessUnit 
   * @param {*} connectRoutingProfile 
   * @param {*} comparison 
   */
  async loadFilteredData (
    businessUnit=this.state.businessUnit, 
    routingProfile=this.state.routingProfile, 
    connectBusinessUnit=this.state.connectBusinessUnit, 
    connectRoutingProfile=this.state.connectRoutingProfile, 
    comparison=this.state.comparison, 
  ) {
    console.log(`loadFilteredData(businessUnit=${businessUnit}, routingProfile=${routingProfile}, connectBusinessUnit=${connectBusinessUnit}, connectRoutingProfile=${connectRoutingProfile}, comparison=${comparison})`);
    // set the pageIndex to 1 as new data is being displayed
    await this.setStateAsync({businessUnit, routingProfile, connectBusinessUnit, connectRoutingProfile, comparison, pageIndex: 1});
    this.loadData();
  }

  /**
   * Calls the employees api to get the data passing filters set in the state as query parameters
   */
  async loadData() {
    console.log(`loading filtered data with businessUnit ${this.state.businessUnit}, routingProfile ${this.state.routingProfile}, connectBusinessUnit ${this.state.connectBusinessUnit}, ${this.state.connectRoutingProfile} and comparison ${this.state.comparison}`);
    this.setState({ loadingData: true });

    const config = {
      headers: {
        'x-api-key': API_KEYS.LOAD_AGENT_DATA_KEY,
      }
    };

    const pageindex = Math.round((this.state.pageSize * (this.state.pageIndex - 1)) + 1);

    const URL = URLS.LOAD_AGENT_DATA_URL + 
      `?startIndex=${encodeURIComponent(pageindex)}` + 
      `&pageSize=${encodeURIComponent(this.state.pageSize)}` + 
      `&excludeWhitelistUsers=${encodeURIComponent(this.state.excludeWhitelistUsers)}` + 
      `&businessUnit=${encodeURIComponent(this.state.businessUnit)}` + 
      `&routingProfile=${encodeURIComponent(this.state.routingProfile)}` + 
      `&connectBusinessUnit=${encodeURIComponent(this.state.connectBusinessUnit)}` + 
      `&connectRoutingProfile=${encodeURIComponent(this.state.connectRoutingProfile)}` + 
      `&displayFilter=${encodeURIComponent(this.state.comparison)}` + 
      `&displaySort=${this.state.displaySort}` + 
      `&displaySortDirection=${this.state.sortDirection}`;

    console.log('Now calling URL: ' + URL);
    axios.get(URL, config)
    .then((response) => {
      console.log(response);

      this.setState({ loadingData: false });

      let data = response && response.data && response.data.dataSet ? response.data.dataSet : [];
      console.log('Data: ' + JSON.stringify(data, null, 2));

      let totalsArray = response && response.data && response.data.totals ? response.data.totals : [];
      let comparisonCounts = {
        "All": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
      };

      console.log('Now looping through counts');
      totalsArray.forEach((total, i) => {
        comparisonCounts[total.COMPARISON] = total.count;
        comparisonCounts.All += total.count;
      });

      console.log('comparisonCounts: ' + JSON.stringify(comparisonCounts, null, 2));

      let pageCount = 0;
      let totalRows = 0;

      if(comparisonCounts === "All") {
        totalRows = comparisonCounts.All
      }
      else {
        var comparisons = this.state.comparison.split(',');
        comparisons.forEach((comp) => {
          totalRows += comparisonCounts[comp];
        })
      }

      pageCount = Math.ceil(totalRows / this.state.pageSize);

      console.log('pageCount: ' + JSON.stringify(pageCount, null, 2));

      this.setState({data, pageCount, comparisonCounts, selectedRows: [], totalRows});
    })
    .catch((err) => {
      console.log(err);
      this.setState({ loadingData: false });
    });
  }

  /**
   * Called once on load. Gets the lists of FAs and SGs for drop down selects
   */
  async loadBusinessUnitsAndRoutingProfiles() {
    console.log(`loading lists of businessUnits and routingProfiles`);

    const initialConfig = {
      headers: {
        'x-api-key': API_KEYS.LOAD_BUSINESS_UNITS_AND_ROUTING_PROFILES_KEY,
      }
    };

    const initialURL = URLS.LOAD_BUSINESS_UNITS_AND_ROUTING_PROFILES_URL;

    console.log('Now calling URL: ' + initialURL);
    axios.get(initialURL, initialConfig)
    .then((response) => {
      console.log(response);

      let businessUnits = response && response.data && response.data.businessUnits ? response.data.businessUnits : [];
      let routingProfiles = response && response.data && response.data.routingProfiles ? response.data.routingProfiles : [];
      let connectBusinessUnits = response && response.data && response.data.connectBusinessUnits ? response.data.connectBusinessUnits : [];
      let connectRoutingProfiles = response && response.data && response.data.connectRoutingProfiles ? response.data.connectRoutingProfiles : [];

      businessUnits.sort((a, b) => {
        if(a.BUSINESS_UNIT > b.BUSINESS_UNIT) return 1;
        if(a.BUSINESS_UNIT < b.BUSINESS_UNIT) return -1;
        return 0;
      });
      routingProfiles.sort((a, b) => {
        if(a.ROUTING_PROFILE > b.ROUTING_PROFILE) return 1;
        if(a.ROUTING_PROFILE < b.ROUTING_PROFILE) return -1;
        return 0;
      });

      businessUnits.push({ BUSINESS_UNIT: "MISSING" });
      routingProfiles.push({ ROUTING_PROFILE: "MISSING" });
      connectBusinessUnits.push({ BUSINESS_UNIT: "MISSING" });
      connectRoutingProfiles.push({ ROUTING_PROFILE: "MISSING" });

      console.log(`businessUnits: ${JSON.stringify(businessUnits, null, 2)}\r\n routingProfiles: ${JSON.stringify(routingProfiles, null, 2)}`);

      this.setState({ businessUnits, routingProfiles, connectBusinessUnits, connectRoutingProfiles });
    })
    .catch((err) => {
      console.log(err);
    });
  }

  /**
   * Sets the state with the passed in object returning a promise that can be awaited that returns once the state is set
   * @param {*} state 
   */
  async setStateAsync (state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    })
  }

  async initialListSyncs() {
    console.log('Polling to see if sync has finished');
    let URL = URLS.LIST_AGENT_SYNC_URL;
    let body = API_BODY.START_AGENT_SYNC_BODY;
    
    axios.post(URL, body)
    .then((response) => {

      console.log(response);

      let { executionArn } = response && response.data && response.data[0] ? response.data[0] : { status: 'ERROR', percentage: 0 };

      this.monitorSync(executionArn);
    })
    .catch((err) => {
      console.log(err);
      this.setState({ syncStatus: 'ERROR' });
    });
  }

  /**
   * Starts the process of syncing all the records from EWFM and Connect
   */
  async syncAll() {
    console.log('Sync all button pressed');

    if (this.state.syncStatus === 'RUNNING') {
      console.log('Already running sync');
      return;
    }
    else if (this.state.data && this.state.data[0].ROWNUMBER === '-1') {
      console.log('No data loaded');
      return;
    }

    this.setState({syncStatus: 'RUNNING'});

    const URL = URLS.START_AGENT_SYNC_URL;

    let body = API_BODY.START_AGENT_SYNC_BODY;
    body.agentFilters = {
      "displayFilter": this.state.comparison, // all the other filers are the same as you've previously used
      "displaySort": this.state.displaySort,
      "displaySortDirection": this.state.sortDirection,
      "businessUnit": this.state.businessUnit,
      "routingProfile": this.state.routingProfile,
      "connectBusinessUnit": this.state.connectBusinessUnit,
      "connectRoutingProfile": this.state.connectRoutingProfile,
      "excludeWhitelistUsers": this.state.excludeWhitelistUsers,
    };
    body.startedBy = this.props.payroll;

    console.log('Now calling URL: ' + URL + '\nbody: ' + JSON.stringify(body, null, 2));
    axios.post(URL, body)
    .then((response) => {
      console.log(response);

      let started = response && response.data && response.data.mode ? response.data.mode === 'start' : false;
      let startDateString = response && response.data ? response.data.startDate : false;

      try{
        let startDate = moment.utc(startDateString);
        startDate = moment(startDate).local();
        if(startDate)
          this.setState({syncLastRunTime: startDate});
      }
      catch(e) {
        console.log("Unable to parse sync start date.");
        console.error(e);
      }

      if(started){
        console.log('Successfully started sync');
        let executionArn = response && response.data && response.data.result ? response.data.result.executionArn : false;

        this.setState({ syncStatus: 'RUNNING', syncPercentage: 0 });
        setTimeout(this.monitorSync.bind(this, executionArn), 1000);
      }
      else if(response && response.data && response.data.status) {
        console.log('Already sync running');
        let { status, percentage, executionArn } = response.data;

        this.setState({ syncStatus: status, syncPercentage: percentage });
        setTimeout(this.monitorSync.bind(this, executionArn), 1000);
      }
      else {
        console.log('Unable to start sync');
        this.setState({ syncStatus: ''});
      }
    })
    .catch((err) => {
      console.log(err);
      this.setState({ syncStatus: 'ERROR'});
    });
  }

  /**
   * Syncs heirarchy for the selected rows
   */
  async syncSelected() {
    console.log('Sync selected button pressed');

    if (this.state.syncStatus === 'RUNNING') {
      console.log('Already loading sync');
      return;
    }
    else if(this.state.selectedRows[0] === '-1') {
      console.log('Not syncing because row selecting is placeholder');
      return;
    }

    this.setState({syncStatus: 'RUNNING'});

    const URL = URLS.START_AGENT_SYNC_URL;


    let body = API_BODY.START_AGENT_SYNC_BODY;
    body.agentFilters = {
      "displayFilter": this.state.comparison,
      "displaySort": this.state.displaySort,
      "displaySortDirection": this.state.sortDirection,
      "businessUnit": this.state.businessUnit,
      "routingProfile": this.state.routingProfile,
      "connectBusinessUnit": this.state.connectBusinessUnit,
      "connectRoutingProfile": this.state.connectRoutingProfile,
      "rowsSelected": this.state.selectedRows,
      "excludeWhitelistUsers": this.state.excludeWhitelistUsers,
    };
    body.startedBy = this.props.payroll;

    console.log('Now calling URL: ' + URL + '\nbody: ' + JSON.stringify(body, null, 2));
    axios.post(URL, body)
    .then((response) => {
      console.log(response);

      let started = response && response.data ? response.data.mode === 'start' : false;
      let startDateString = response && response.data ? response.data.startDate : false;

      try{
        let startDate = moment.utc(startDateString);
        startDate = moment(startDate).local();
        if(startDate)
          this.setState({syncLastRunTime: startDate});
      }
      catch(e) {
        console.log("Unable to parse sync start date.");
        console.error(e);
      }

      if(started) {
        console.log('Successfully started sync');
        let executionArn = response && response.data && response.data.result ? response.data.result.executionArn : false;
        this.setState({ syncStatus: 'RUNNING', syncPercentage: 0 });
        setTimeout(this.monitorSync.bind(this, executionArn), 1000);
      }
      else if(response && response.data && response.data.status) {
        console.log('Already sync running');
        let { status, percentage, executionArn } = response.data;

        this.setState({ syncStatus: status, syncPercentage: percentage });
        setTimeout(this.monitorSync.bind(this, executionArn), 1000);
      }
      else {
        console.log('Unable to start sync');
        this.setState({ syncStatus: 'RUNNING' });
      }
    })
    .catch((err) => {
      console.log(err);
      this.setState({ syncStatus: 'ERROR'});
    });
  }

  /**
   * Polls the monitor method on the sync API to get the progress of the sync 
   * @param {*} executionArn 
   */
  async monitorSync (executionArn) {
    console.log('Polling to see if sync has finished');
    let URL = URLS.MONITOR_AGENT_SYNC_URL;
    let body = { executionArn };
    
    axios.post(URL, body)
    .then((response) => {

      console.log(response);

      let { status, percentage, errorMessage, lastUpdateTime, startedBy } = response && response.data ? response.data : { status: 'ERROR', percentage: 0 };

      if(lastUpdateTime) {
        try {
          let lastRun = moment.utc(lastUpdateTime, 'MMM DD YYYY  hh:mmA');
          lastRun = moment(lastRun).local();
          this.setState({syncLastRunTime: lastRun});
        }
        catch(e) {
          console.log("Unable to parse last sync lastUpdateTime");
        }
      }

      console.log(`Sync status is ${status} and percentage ${percentage}`)

      if(!percentage) percentage = this.state.syncPercentage;

      switch(status) {
        case "RUNNING":
        case "INDETERMINATE":
          console.log('Sync still running');
  
          this.setState({ syncStatus: status, syncPercentage: percentage });
          setTimeout(this.monitorSync.bind(this, executionArn), 1000);
          break;
        case "FAILED":
          if(errorMessage){
            console.log('Sync failed for reason ' + JSON.stringify(errorMessage));
            if(typeof(errorMessage) === 'string')
              alert(errorMessage);
          }
          else {
            console.log('Sync failed for unknown reason');
          }

          this.setState({ syncStatus: status, syncPercentage: 0 });

          //reload to get new data
          this.loadBusinessUnitsAndRoutingProfiles();
          this.loadData();
          break;
        default:
          console.log('Sync finished');
          this.setState({ syncStatus: status, syncPercentage: 0 });
  
          //reload to get new data
          this.loadBusinessUnitsAndRoutingProfiles();
          this.loadData();
          break;
      }

      //Set the last user that ran the sync
      if(startedBy) {
        this.setState({syncRunBy: startedBy});
      }
    })
    .catch((err) => {
      console.log(err);
      this.setState({ syncStatus: 'ERROR' });
    });
  }

  /**
   * Captures click event on the cancel button for sync. Calls API to end currently running sync.
   */
  async interputSync () {
    console.log('Cancel current sync');
    let URL = URLS.INTERRUPT_AGENT_SYNC_URL;
    let body = {};
    
    axios.post(URL, body)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
      this.setState({ syncStatus: 'ERROR' });
    });
  }

  /**
   * Updates the page index/number in the state and calls loadData to get the data for the new page
   * @param {*} newPageIndex 
   */
  async updatePageIndex(newPageIndex) {
    await this.setStateAsync({pageIndex: newPageIndex});
    this.loadData();
  }

  /**
   * Calculates the new page index for the new page size, updates both in the state and calls loadData
   * @param {*} newPageSize 
   */
  async updatePageSize(newPageSize) {
    console.log('Called updatePageSize with newPageSize: ' + newPageSize.toString());
    let newPageIndex = Math.floor((this.state.pageSize / newPageSize) * this.state.pageIndex);
    let newPageCount =  Math.ceil(this.state.totalRows / newPageSize);
    if (newPageIndex < 1) newPageIndex = 1
    else if(newPageIndex > newPageCount) newPageIndex = newPageCount;

    await this.setStateAsync({ pageSize: newPageSize, pageCount: newPageCount, pageIndex: newPageIndex });
    console.log(`Updated page size to ${this.state.pageSize}, pageCount to ${this.state.pageCount} and pageIndex to ${this.state.pageIndex}`);
    this.loadData();
  }

  async onClickShowWhitelistedUsers(checked) {
    await this.setStateAsync(this.setState({ excludeWhitelistUsers: checked ? 1 : 0 }));

    this.loadData();
  }

  /**
   * Updates the column to sort on. Sets the display sort in the state and the sortDirection if there's no change and calls loadData
   * @param {*} displaySort 
   * @param {*} displayHeader 
   * @returns 
   */
  async updateDisplaySort(displaySort, displayHeader) {
    console.log('Sorting by ' + displaySort + ' display header: ' + displayHeader);
    if(!displaySort || displayHeader === 'EWFM' || displayHeader === 'Connect')
      return;

    let sortDirection = 'DESC';
    if (this.state.sortDirection === 'DESC') {
      sortDirection = 'ASC';
    }
    else {
      sortDirection= 'DESC';
    }

    await this.setStateAsync({displaySort, sortDirection});

    this.loadData();
  }

  async getExportUrl() {
    if(!this.state.gettingExport) {
      this.setState({gettingExport: true});

      const config = {
        headers: {
          'x-api-key': API_KEYS.EXPORT_KEY,
        }
      };

      const URL = URLS.EXPORT_URL + 
        `?displayFilter=${encodeURIComponent(this.state.comparison)}` +
        `&ewfmBusinessUnit=${encodeURIComponent(this.state.businessUnit)}` + 
        `&ewfmRoutingProfile=${encodeURIComponent(this.state.routingProfile)}` + 
        `&connectBusinessUnit=${encodeURIComponent(this.state.connectBusinessUnit)}` + 
        `&connectRoutingProfile=${encodeURIComponent(this.state.connectRoutingProfile)}` + 
        `&excludeWhitelistUsers=${encodeURIComponent(this.state.excludeWhitelistUsers)}` + 
        '&exportMode=Agents';

      console.log('Now calling URL: ' + URL);
      axios.get(URL, config)
      .then((response) => {
        console.log("RESPONSE FROM EXPORT " + JSON.stringify(response));
        this.setState({gettingExport: false});
        var {success, url, message} = response ? response.data: null;
        if(success)
          window.location.assign(url);
        else
          window.alert(`Error getting extract\r\n${message}`);
      });

      console.log("Getting export");
    }
    else {
      console.log("Already getting export");
    }
  }

  render() {
    const {
      runningEwfmEtlProcess,
      ewfmEtlProgress,
      ewfmEtlLastUpdateTime,
      runningConnectEtlProcess,
      connectEtlProgress,
      connectEtlLastUpdateTime,
      startEwfmEtlProcess,
      startConnectEtlProcess,
    } = this.props;

    return (
      <>
        <div className={'hierarchy-container' + (this.state.loadingData ? ' loading' : '')}>
          <Filters 
            businessUnits={this.state.businessUnits} 
            routingProfiles={this.state.routingProfiles} 
            connectBusinessUnits={this.state.connectBusinessUnits} 
            connectRoutingProfiles={this.state.connectRoutingProfiles} 
            comparisonCounts={this.state.comparisonCounts}
            loadFilteredData={(businessUnit, routingProfile, connectBusinessUnit, connectRoutingProfile, comparison, ) => this.loadFilteredData(businessUnit, routingProfile, connectBusinessUnit, connectRoutingProfile, comparison)} 
            runningEwfmEtlProcess={runningEwfmEtlProcess} 
            ewfmEtlProgress={ewfmEtlProgress} 
            ewfmEtlLastUpdateTime={ewfmEtlLastUpdateTime} 
            startEwfmEtlProcess={() => startEwfmEtlProcess()} 
            runningConnectEtlProcess={runningConnectEtlProcess} 
            connectEtlProgress={connectEtlProgress} 
            connectEtlLastUpdateTime={connectEtlLastUpdateTime} 
            startConnectEtlProcess={() => startConnectEtlProcess()} 
            showEtlButtons={true} 
          />
          <div className='flex-row'>
            <FormGroup id="show-whitelisted-users-switch">
              <FormControlLabel 
                label="Exclude Whitelisted Users" 
                control={
                  <Switch 
                    checked={this.state.excludeWhitelistUsers === 1} 
                    onChange={(e) => this.onClickShowWhitelistedUsers(e.target.checked)} 
                  />
                } 
              />
            </FormGroup>
          </div>
          <div className='all-buttons-container'>
            <div className="export-container">
              <Tooltip 
                title="Export list of users into a .csv file." 
                describeChild 
                placement="right" 
              >
                <Button
                    variant="contained"
                    id="export-button" onClick={() => this.getExportUrl()}
                    className="export-button"
                    disabled={this.state.gettingExport}
                    disableElevation
                >
                  Export to CSV
                </Button>
              </Tooltip>
              {
                this.state.gettingExport ?
                <CircularProgress size={25} /> :
                null
              }
            </div>
            <div className="sync-buttons-container">
              <div id="sync-status-container">
                {
                  (this.state.syncStatus === 'RUNNING' || this.state.syncStatus === 'INDETERMINATE') ? 
                    (
                      <span>
                        Sync Started {this.state.syncLastRunTime && typeof(this.state.syncLastRunTime.format) === 'function' ? this.state.syncLastRunTime.format('YYYY-MM-DD HH:mm:ss') : ''} {this.state.syncRunBy ? `by ${this.state.syncRunBy}` : ''}
                      </span>
                    ) : 
                    (
                      this.state.syncStatus ?
                      <span id="sync-status-header" className={this.state.syncStatus}>
                        Sync Last Run {this.state.syncLastRunTime && typeof(this.state.syncLastRunTime.format) === 'function' ? this.state.syncLastRunTime.format('YYYY-MM-DD HH:mm:ss') : ''} {this.state.syncRunBy ? `by ${this.state.syncRunBy}` : ''} {this.state.syncStatus ? ` - ${this.state.syncStatus}` : ''}
                      </span>
                      :
                      null
                    )
                }
              </div>
              {
                this.state.syncStatus === 'RUNNING' ?
                  <Button
                    variant="outlined"
                    id="stop-sync-button"
                    onClick={() => this.interputSync()}
                    color="error"
                    disableElevation
                  >Stop Sync</Button>
                  :
                  null
              }
              <Button
                variant="contained"
                id="sync-all-button"
                onClick={() => this.syncAll()}
                className="sync-button"
                disabled={!this.state.comparisonCounts || this.state.comparisonCounts.All === '0' || this.state.syncStatus === 'RUNNING' || runningEwfmEtlProcess || runningConnectEtlProcess}
                disableElevation
              >Sync All</Button>
              <Button
                variant="contained"
                id="sync-selected-button"
                className="sync-button"
                disabled={this.state.syncStatus === 'RUNNING' || !this.state.selectedRows || this.state.selectedRows.length === 0 || runningEwfmEtlProcess || runningConnectEtlProcess}
                onClick={() => this.syncSelected()}
                disableElevation
              >Sync Selected</Button>
            </div>
          </div>
          <div className="progress-container">
            {
              this.state.syncStatus === 'RUNNING' || this.state.syncStatus === 'INDETERMINATE' ? 
              <LinearProgress
                variant="determinate"
                value={this.state.syncPercentage}
              /> :
              ''
            }
          </div>
          <Table
            columns={this.state.columns}
            hiddenColumns={this.state.hiddenColumns}
            data={this.state.data}
            pageSize={this.state.pageSize}
            pageIndex={this.state.pageIndex}
            pageCount={this.state.pageCount}
            separatorColumn="CONNECT_USERNAME"
            displaySort={this.state.displaySort}
            sortDirection={this.state.sortDirection}
            selectedRows={this.state.selectedRows}
            selectRow={(rowNumber) => this.selectRow(rowNumber)}
            selectAllRows={(rowNumber) => this.selectAllRows(rowNumber)}
            updatePageIndex={(newPageIndex) => this.updatePageIndex(newPageIndex)}
            updatePageSize={(newPageSize) => this.updatePageSize(newPageSize)}
            updateDisplaySort={(displaySort, displayHeader) => this.updateDisplaySort(displaySort, displayHeader)}
          />
        </div>
      </>
    );
  }
}