import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Tabs from './components/Tabs';
import Amplify, { Auth } from 'aws-amplify';
import { Button } from '@mui/material';
import AgentHierarchy from './components/AgentHierarchy';
import EWFMConnectHierarchy from './components/EWFMConnectHierarchy';
import RoutingProfiles from './components/RoutingProfiles';
import About from './components/About';
import UserWhitelist from './components/UserWhitelist';
import { AWS_CONFIG } from "./configs/aws";
import { API_KEYS, URLS, API_BODY } from "./configs/apis";
import './styles/App.css';

Amplify.configure({
  "aws_project_region": AWS_CONFIG.REGION,
  "aws_user_pools_id": AWS_CONFIG.USER_POOL_ID,
  "aws_user_pools_web_client_id": AWS_CONFIG.APP_CLIENT_ID,
  "Auth": {
      "region": AWS_CONFIG.REGION, 
      "userPoolId": AWS_CONFIG.USER_POOL_ID,
      "oauth": {
        "domain": AWS_CONFIG.DOMAIN,
        "scope": AWS_CONFIG.SCOPE,
        "redirectSignIn": AWS_CONFIG.REDIRECT_SIGN_IN,
        "redirectSignOut": AWS_CONFIG.REDIRECT_SIGN_OUT,
        "responseType": AWS_CONFIG.RESPONSE_TYPE,
      }
  }
});

export default class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      userVerified: false,
      user: null,
      payrollNumber: '862391',
      runningEwfmEtlProcess: false,
      ewfmEtlProgress: 0,
      ewfmEtlLastUpdateTime: '0000-00-00T00:00:00.000Z',
      ewfmEtlLastRunBy: '',
      ewfmEtlLastRetrieveRecordCount: 600000,
      runningConnectEtlProcess: false,
      connectEtlProgress: 0,
      connectEtlLastUpdateTime: '0000-00-00T00:00:00.000Z',
      connectEtlLastRunBy: '',
      ewfmHierarchyKey: 'ewfmKey',
      agentHierarchyKey: 'agentKey',
    };
  }

  async componentDidMount() {
    await this.loginUser();
    this.monitorEwfmEtlProcess();
    this.initialConnectEtlProcess();
  }

  async loginUser() {
    try {
      console.log('Logging in user');

      //Check to see if already logged in
      let user = await Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log('Found authenticated user');
        console.log(user);

        this.setState({ user });
        return user;
      })
      .catch(err => null);

      if(!user) {
        Auth.federatedSignIn({
          provider: AWS_CONFIG.PROVIDER,
        })
        .then(cred => {
          console.log(cred);
          return Auth.currentAuthenticatedUser();
        })
        .then(user => {
          console.log(user);
          this.setState({ user });
        })
        .catch(e => {
          console.log(e);
        });
      }
      else {
        var payrollNumber = (user && 
          user.signInUserSession && 
          user.signInUserSession.idToken && 
          user.signInUserSession.idToken.payload && 
          user.signInUserSession.idToken.payload.identities && 
          user.signInUserSession.idToken.payload.identities[0]) ? 
          user.signInUserSession.idToken.payload.identities[0].userId : null;

        if(payrollNumber) {
          this.setState({ payrollNumber, userVerified: true });
          this.verifyUser(payrollNumber);
        }
        else {
          this.setState({ payrollNumber: '0', userVerified: false });
        }
      }
    }
    catch (e) {
      console.log(JSON.stringify(e));
      this.setState({ userVerified: false });
    }
  }

  async verifyUser(payrollNumber) {
    console.log('Verifing user with payroll: ' + payrollNumber.toString());

    const url = `${URLS.VERIFY_USER_URL}?payroll=${payrollNumber}`;
    const config = {
      headers: {
        'x-api-key': API_KEYS.VERIFY_USER_KEY,
      }
    };

    axios.get(url, config)
    .then((response) => {
      const userType = response && response.data ? response.data : {};
      console.log('User type is ' + userType);

      if(userType === 'ADMIN' || userType === 'Admin') {
        this.setState({ userVerified: true });
      }
      else {
        this.setState({ userVerified: false });
      }
    });
  }

  /**
   * Makes a call to the EWFM ETL API to start the EWFM ETL process. If already started still returns success and nothing happens.
   */
   async startEwfmEtlProcess() {
    console.log('Now starting ETL process');

    const url = URLS.START_EWFM_ETL_URL + `?payroll=${this.state.payrollNumber}`;

    const config = {
      headers: {
        'x-api-key': API_KEYS.START_EWFM_ETL_PROCCESS_KEY,
      }
    };

    axios.get(url, config)
    .then((response) => {
      if(response && response.data)
        console.log(`Received data from start call for EWFM ETL process: ${JSON.stringify(response.data, null, 2)}`);
      else
        console.log(`No body in response from start call for EWFM ETL process: ${JSON.stringify(response, null, 2)}`);

      console.log('Setting runningEwfmEtlProcess to true')
      this.setState({ runningEwfmEtlProcess: true , ewfmEtlProgress: 0 });
    })
    .catch((err) => {
      console.log(err);
      this.setState({ runningEwfmEtlProcess: false , ewfmEtlProgress: 0 });
    });
  }

  /**
   * Polls the moitor method on the EWFM ETL API to see if there is an ETL in progress and calculate how far it is.
   */
  async monitorEwfmEtlProcess() {
    const url = URLS.MONITOR_EWFM_ETL_URL;

    const config = {
      headers: {
        'x-api-key': API_KEYS.MONITOR_EWFM_ETL_PROCCESS_KEY,
      }
    };

    axios.get(url, config)
    .then((response) => {
      const { PROCESSING, RETRIEVE_RECORD_COUNT, LAST_UPDATE_TIME, ETL_FLAG, progress } = response && response.data && response.data.body ? response.data.body : null;

      try {
        let updateDate = moment.utc(LAST_UPDATE_TIME, 'YYYY-MM-DDTHH:mm:ss.SSS');
        updateDate = moment(updateDate).local();
        if(updateDate !== this.state.ewfmEtlLastUpdateTime)
          this.setState({ ewfmEtlLastUpdateTime: updateDate });
      }
      catch {
        console.log(`Unable to parse date string ${LAST_UPDATE_TIME} for last updated time for ETL EWFM process.`);
      }

      //Check both to see if process has started as the start request can be sitting in RDS but not polled yet as only polled every 30 seconds
      if(PROCESSING === 'Y' || ETL_FLAG === 'Y') {
        console.log(`Updating runningEwfmEtlProcess: true and progress: ${progress.toString()}`);
        if(PROCESSING === 'N')
          this.setState({ runningEwfmEtlProcess: true , ewfmEtlProgress: 0 });
        else
          this.setState({ runningEwfmEtlProcess: true , ewfmEtlProgress: progress });
        setTimeout(this.monitorEwfmEtlProcess.bind(this), 3000);
      }
      else {
        //Only update the state if it needs to be changed
        if(this.state.runningEwfmEtlProcess !== false) {
          console.log('Updating runningEwfmEtlProcess: false and progress: 0');
          this.setState({ runningEwfmEtlProcess: false , ewfmEtlProgress: 0 });

          //Set new keys on components to make them re-render and get new data
          this.setState({
            ewfmHierarchyKey: 'EwfmKey' + new Date().getTime(), 
            agentHierarchyKey: 'AgentKey' + new Date().getTime()
          });
        }

        if(this.state.ewfmEtlLastRetrieveRecordCount !== RETRIEVE_RECORD_COUNT)
          this.setState({ ewfmEtlLastRetrieveRecordCount: RETRIEVE_RECORD_COUNT });

        //Have slower poll time as no current process
        setTimeout(this.monitorEwfmEtlProcess.bind(this), 15000);
      }
    })
    .catch((err) => {
      console.log(err);
      console.log('Updating runningEwfmEtlProcess: false and progress: 0');
      this.setState({ runningEwfmEtlProcess: false , ewfmEtlProgress: 0 });
      setTimeout(this.monitorEwfmEtlProcess.bind(this), 60000);
    });
  }

  async initialConnectEtlProcess() {
    //console.log('Polling to monitor Connect ETL Process');
    const url = URLS.INITIAL_CONNECT_ETL_URL;

    const body = API_BODY.INITIAL_CONNECT_ETL_BODY;

    axios.post(url, body)
    .then((response) => {
      const { status, lastUpdateTime, executionArn } = response && response.data ? response.data : null;

      try {
        let updateDate = moment.utc(lastUpdateTime, 'MMM DD YYYY  hh:mmA');
        updateDate = moment(updateDate).local();
        //console.log(`Set runningConnectEtlProcess: false and connectEtlLastUpdateTime to moment date`);
        this.setState({ connectEtlLastUpdateTime: updateDate });
      } catch {
        console.log(`Unable to parse date string ${lastUpdateTime} for last updated time for ETL Connect process.`);
      }

      if (status === 'RUNNING' && this.state.runningConnectEtlProcess === false && executionArn) {
        const percentage = response && response.data && response.data.monitorExecution ? response.data.monitorExecution.percentage : 0;
        this.setState({ runningConnectEtlProcess: true , connectEtlProgress: percentage });
        setTimeout(this.monitorConnectEtlProccess.bind(this, executionArn), 3000);
      }

      setTimeout(this.initialConnectEtlProcess.bind(this), 30000);
    })
    .catch((err) => {
      console.log(err);
      console.log('Updating runningConnectEtlProcess: false and progress: 0');
      this.setState({ runningConnectEtlProcess: false , connectEtlProgress: 0 });
    });
  }

  /**
   * Placeholder for function to call API to start Connect ETL process
   */
  async startConnectEtlProcess() {
    console.log('Starting Connect ETL Proccess');

    const URL = URLS.START_CONNECT_ETL_URL;

    const body = API_BODY.START_CONNECT_ETL_BODY;

    console.log('Now calling URL: ' + URL + '\nbody: ' + JSON.stringify(body, null, 2));
    axios.post(URL, body)
    .then((response) => {
      if(response && response.data)
        console.log(`Received data from start call for Connect ETL process: ${JSON.stringify(response.data, null, 2)}`);
      else
        console.log(`No body in response from start call for Connect ETL process: ${JSON.stringify(response, null, 2)}`);

      const  { mode, result, status, executionArn } = response && response.data ? response.data : false;

      if(mode === 'start' && result && result.executionArn) {
        console.log('Connect ETL started now monitoring');
        this.setState({ runningConnectEtlProcess: true , connectEtlProgress: 0 });
        setTimeout(this.monitorConnectEtlProccess.bind(this, result.executionArn), 3000);
      }
      else if(status === 'RUNNING' && !!executionArn) {
        console.log('Already Connect ETL running ');
        this.setState({ runningConnectEtlProcess: true , connectEtlProgress: response.data.progress ? response.data.progress : 0 });
        setTimeout(this.monitorConnectEtlProccess.bind(this, result.executionArn), 3000);
      }
      else {
        console.log('Process not started');
      }
    })
    .catch((err) => {
      console.log(err);
      this.setState({ syncStatus: ''});
    });
  }

  async monitorConnectEtlProccess(executionArn) {
    console.log('Polling to monitor Connect ETL Process executionArn: ' + executionArn);
    const url = URLS.MONITOR_CONNECT_ETL_URL;

    const body = {
      executionArn
    };

    axios.post(url, body)
    .then((response) => {
      if(response && response.data)
        console.log(`Received data from monitor call for Connect ETL process: ${JSON.stringify(response.data, null, 2)}`);
      else
        console.log(`No body in response from monitor call for Connect ETL process: ${JSON.stringify(response, null, 2)}`);

      const { status, percentage, lastUpdateTime } = response && response.data ? response.data : null;

      switch(status) {
        case 'RUNNING':
          this.setState({ runningConnectEtlProcess: true , connectEtlProgress: percentage });
          setTimeout(this.monitorConnectEtlProccess.bind(this, executionArn), 3000);
          break;
        case 'SUCCEEDED':
          try{
            console.log(`Now parsing date string ${lastUpdateTime} for last updated time for ETL Connect process.`);
            //                                       Feb 11 2022  5:59PM
            let updateDate = moment.utc(lastUpdateTime, 'MMM DD YYYY  hh:mmA');
            updateDate = moment(updateDate).local();
            console.log(`Set runningConnectEtlProcess: false and connectEtlLastUpdateTime to moment date`);
            this.setState({ runningConnectEtlProcess: false, connectEtlLastUpdateTime: updateDate });

            //Set new keys on components to make them re-render and get new data
            this.setState({
              ewfmHierarchyKey: 'EwfmKey' + new Date().getTime(), 
              agentHierarchyKey: 'AgentKey' + new Date().getTime()
            });
          }
          catch {
            console.log(`Unable to parse date string ${lastUpdateTime} for last updated time for ETL Connect process.`);
            this.setState({ runningConnectEtlProcess: false });
          }
          break;
        case 'FAILED':
          this.setState({ runningConnectEtlProcess: false , connectEtlProgress: 0 });
          break;
        case 'CANCELLED':
          this.setState({ runningConnectEtlProcess: false , connectEtlProgress: 0 });
          break;
        case 'INDTERMINATE':
          this.setState({ runningConnectEtlProcess: true , connectEtlProgress: 0 });
          setTimeout(this.monitorConnectEtlProccess.bind(this, executionArn), 3000);
          break; 
        default:
          this.setState({ runningConnectEtlProcess: false , connectEtlProgress: 0 });
      }
    })
    .catch((err) => {
      console.log(err);
      console.log('Updating runningConnectEtlProcess: false and progress: 0');
      this.setState({ runningConnectEtlProcess: false , connectEtlProgress: 0 });
      setTimeout(this.monitorConnectEtlProccess.bind(this, executionArn), 60000);
    });
  }

  render() {
    return (
      <div id="app-container">
        {
          this.state.userVerified ? 
          (
            <Tabs>
              <div label="Agents">
                <AgentHierarchy
                  key={this.state.agentHierarchyKey}
                  runningEwfmEtlProcess={this.state.runningEwfmEtlProcess}
                  ewfmEtlProgress={this.state.ewfmEtlProgress}
                  ewfmEtlLastUpdateTime={this.state.ewfmEtlLastUpdateTime}
                  runningConnectEtlProcess={this.state.runningConnectEtlProcess}
                  connectEtlProgress={this.state.connectEtlProgress}
                  connectEtlLastUpdateTime={this.state.connectEtlLastUpdateTime}
                  startEwfmEtlProcess={() => this.startEwfmEtlProcess()}
                  startConnectEtlProcess={() => this.startConnectEtlProcess()}
                  payroll={this.state.payrollNumber}
                 />
              </div>
              <div label="Hierarchy">
                <EWFMConnectHierarchy
                  key={this.state.ewfmHierarchyKey}
                  runningEwfmEtlProcess={this.state.runningEwfmEtlProcess}
                  ewfmEtlProgress={this.state.ewfmEtlProgress}
                  ewfmEtlLastUpdateTime={this.state.ewfmEtlLastUpdateTime}
                  runningConnectEtlProcess={this.state.runningConnectEtlProcess}
                  connectEtlProgress={this.state.connectEtlProgress}
                  connectEtlLastUpdateTime={this.state.connectEtlLastUpdateTime}
                  startEwfmEtlProcess={() => this.startEwfmEtlProcess()}
                  startConnectEtlProcess={() => this.startConnectEtlProcess()}
                />
              </div>
              <div label="Routing Profiles">
                <RoutingProfiles />
              </div>
              <div label="Whitelisted Users">
                <UserWhitelist />
              </div>
              <div label="About">
                <About />
              </div>
            </Tabs>
          ) : 
          (
            <div className="login-container">
              <h3>
                { this.state.payrollNumber ? "I'm afraid you are not verified to use this application" : 'You will be automatically redirected to the sign in page. If you are not redirected in 5 seconds please click the button below.'}
              </h3>
              <Button id="login-button" variant="contained" disableElevation href={URLS.MANUAL_SSO_LOGIN_REDIRECT_URL}>Sign-in</Button>
            </div>
          )
        }
      </div>
    );
  }
}