import React from 'react';
import axios from 'axios';
import { Button, LinearProgress } from '@mui/material';
import Table from './Table.js';
import Filters from './Filters.js';
import { URLS, API_KEYS, API_BODY } from '../configs/apis.js';

export default class EWFMConnectHierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{
        'EWFM_HIERARCHY_HASH': '-',
        'CONNECT_HIERARCHY_HASH': '-',
        'COMPARISON': '-',
        'ROWNUMBER': '-1',
      }],
      columns: [
        {
          Header: 'EWFM Hierarchy',
          accessor: 'EWFM_HIERARCHY_HASH',
        },
        {
          Header: 'Connect Hierarchy',
          accessor: 'CONNECT_HIERARCHY_HASH',
        },
        {
          Header: 'Comparison',
          accessor: 'COMPARISON',
        },
        {
          Header: 'Row Number',
          accessor: 'ROWNUMBER',
        },
      ],
      hiddenColumns: [
        'COMPARISON',
        'ROWNUMBER',
      ],
      businessUnit: 'All', 
      routingProfile: 'All', 
      connectBusinessUnit: 'All', 
      connectRoutingProfile: 'All', 
      businessUnits: [],
      routingProfiles: [],
      connectBusinessUnits: [], 
      connectRoutingProfiles: [],
      comparison: "All", 
      displaySort: "EWFM_HIERARCHY_HASH",
      selectedRows: [],
      sortDirection: 'ASC',
      pageIndex: 1,
      pageSize: 50,
      pageCount: 0,
      comparisonCounts: {
        "All": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
      },
      syncStatus: '',
      loadingData: false,
      totalRows: 0
    };
  }

  /**
   * Called after render
   */
  componentDidMount() {
    this.loadbusinessUnitsAndroutingProfiles();
    this.loadData();
  }

  /**
   * Adds the row numbers for all displayed data to selected rows array on the state
   */
  selectAllRows() {
    console.log('selectAllRows called');
    var selectedRows = [];

    if(this.state.selectedRows.length < this.state.data.length) {
      selectedRows = this.state.data.map((dataRow) => {
        return dataRow.ROWNUMBER;
      });
    }

    console.log('selectedRows: ' + JSON.stringify(selectedRows, null, 2));
    this.setState({selectedRows});
  }

  /**
   * Adds the given row number to the selectedRows array on the state or removes if already there
   * @param {*} rowNumber 
   */
  selectRow(rowNumber) {
    console.log('Selected row ' + rowNumber);

    var selectedRows = this.state.selectedRows;
    var index = selectedRows.indexOf(rowNumber);

    if(index === -1) {
      selectedRows.push(rowNumber);
    }
    else {
      selectedRows.splice(index, 1);
    }

    console.log('selectedRows: ' + JSON.stringify(selectedRows, null, 2));
    this.setState({selectedRows});
  }

  /**
   * Sets the state for the given filters awaiting the change and then calling loadData
   */
  async loadFilteredData (
    businessUnit = this.state.businessUnit, 
    routingProfile = this.state.routingProfile, 
    connectBusinessUnit = this.state.connectBusinessUnit, 
    connectRoutingProfile = this.state.connectRoutingProfile, 
    comparison = this.state.comparison, 
  ) {
    console.log(`loadFilteredData(businessUnit=${businessUnit}, routingProfile=${routingProfile}, connectBusinessUnit=${connectBusinessUnit}, connectRoutingProfile=${connectRoutingProfile}, comparison=${comparison})`);
    // set the pageIndex to 1 as new data is being displayed
    await this.setStateAsync({businessUnit, routingProfile, connectBusinessUnit, connectRoutingProfile, comparison, pageIndex: 1});
    this.loadData();
  }


  /**
   * Calls the employees api to get the data passing filters set in the state as query parameters
   */
  async loadData() {
    console.log(`loading filtered data with businessUnit ${this.state.businessUnit}, routingProfile ${this.state.routingProfile} and comparison ${this.state.comparison}`);
    this.setState({ loadingData: true });

    const config = {
      headers: {
        'x-api-key': API_KEYS.LOAD_HIERARCHY_DATA_KEY,
      }
    };

    const pageindex = Math.round((this.state.pageSize * (this.state.pageIndex - 1)) + 1);

    const URL = URLS.LOAD_HIERARCHY_DATA_URL + 
      `?startIndex=${encodeURIComponent(pageindex)}&pageSize=${encodeURIComponent(this.state.pageSize)}` + 
      `&businessUnit=${encodeURIComponent(this.state.businessUnit)}&routingProfile=${encodeURIComponent(this.state.routingProfile)}` + 
      `&connectBusinessUnit=${encodeURIComponent(this.state.connectBusinessUnit)}&connectRoutingProfile=${encodeURIComponent(this.state.connectRoutingProfile)}` + 
      `&displayFilter=${encodeURIComponent(this.state.comparison)}&displaySort=${this.state.displaySort}` + 
      `&displaySortDirection=${this.state.sortDirection}`;

    console.log('Now calling URL: ' + URL);
    axios.get(URL, config)
    .then((response) => {
      console.log(response);

      this.setState({ loadingData: false });

      let data = response && response.data && response.data.dataSet ? response.data.dataSet : [];
      console.log('Data: ' + JSON.stringify(data));

      let totalsArray = response && response.data && response.data.totals ? response.data.totals : [];
      let comparisonCounts = {
        "All": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
      };

      console.log('Now looping through counts');
      totalsArray.forEach((total, i) => {
        comparisonCounts[total.COMPARISON] = total.count;
        comparisonCounts.All += total.count;
      });

      console.log('comparisonCounts: ' + JSON.stringify(comparisonCounts, null, 2));

      let pageCount = 0;
      let totalRows = 0;

      if(comparisonCounts === "All") {
        totalRows = comparisonCounts.All
      }
      else {
        var comparisons = this.state.comparison.split(',');
        comparisons.forEach((comp) => {
          totalRows += comparisonCounts[comp];
        })
      }

      pageCount = Math.ceil(totalRows / this.state.pageSize);

      console.log('pageCount: ' + JSON.stringify(pageCount, null, 2));

      this.setState({data, pageCount, comparisonCounts, selectedRows: [], totalRows});
    })
    .catch((err) => {
      console.log(err);
      this.setState({ loadingData: false });
    });
  }

  /**
   * Called once on load. Gets the lists of FAs and SGs for drop down selects
   */
  async loadbusinessUnitsAndroutingProfiles() {
    console.log(`loading lists of businessUnits and routingProfiles`);

    const initialConfig = {
      headers: {
        'x-api-key': API_KEYS.LOAD_BUSINESS_UNITS_AND_ROUTING_PROFILES_KEY,
      }
    };

    const initialURL = URLS.LOAD_BUSINESS_UNITS_AND_ROUTING_PROFILES_URL;

    console.log('Now calling URL: ' + initialURL);
    axios.get(initialURL, initialConfig)
    .then((response) => {
      console.log(response);

      let businessUnits = response && response.data && response.data.businessUnits ? response.data.businessUnits : [];
      let routingProfiles = response && response.data && response.data.routingProfiles ? response.data.routingProfiles : [];
      let connectBusinessUnits = response && response.data && response.data.connectHierarchyBusinessUnits ? response.data.connectHierarchyBusinessUnits : [];
      let connectRoutingProfiles = response && response.data && response.data.connectHierarchyRoutingProfiles ? response.data.connectHierarchyRoutingProfiles : [];

      businessUnits.push({ BUSINESS_UNIT: "MISSING" });
      routingProfiles.push({ ROUTING_PROFILE: "MISSING" });

      businessUnits.sort((a, b) => {
        if(a.BUSINESS_UNIT > b.BUSINESS_UNIT) return 1;
        if(a.BUSINESS_UNIT < b.BUSINESS_UNIT) return -1;
        return 0;
      });
      routingProfiles.sort((a, b) => {
        if(a.ROUTING_PROFILE > b.ROUTING_PROFILE) return 1;
        if(a.ROUTING_PROFILE < b.ROUTING_PROFILE) return -1;
        return 0;
      });
      connectBusinessUnits.sort((a, b) => {
        if(a.BUSINESS_UNIT > b.BUSINESS_UNIT) return 1;
        if(a.BUSINESS_UNIT < b.BUSINESS_UNIT) return -1;
        return 0;
      });
      connectRoutingProfiles.sort((a, b) => {
        if(a.ROUTING_PROFILE > b.ROUTING_PROFILE) return 1;
        if(a.ROUTING_PROFILE < b.ROUTING_PROFILE) return -1;
        return 0;
      });

      console.log(`businessUnits: ${JSON.stringify(businessUnits, null, 2)}\r\n routingProfiles: ${JSON.stringify(routingProfiles, null, 2)}`);

      this.setState({ businessUnits, routingProfiles, connectBusinessUnits, connectRoutingProfiles });
    })
    .catch((err) => {
      console.log(err);
    });
  }

  /**
   * Sets the state with the passed in object returning a promise that can be awaited that returns once the state is set
   * @param {*} state 
   * @returns 
   */
  async setStateAsync (state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    })
  }

  /**
   * Updates the page index/number in the state and calls loadData to get the data for the new page
   * @param {*} newPageIndex 
   */
  async updatePageIndex(newPageIndex) {
    await this.setStateAsync({pageIndex: newPageIndex});
    this.loadData();
  }

  /**
   * Calculates the new page index for the new page size, updates both in the state and calls loadData
   * @param {*} newPageSize 
   */
  async updatePageSize(newPageSize) {
    console.log('Called updatePageSize with newPageSize: ' + newPageSize.toString());
    let newPageIndex = Math.floor((this.state.pageSize / newPageSize) * this.state.pageIndex);
    let newPageCount =  Math.ceil(this.state.totalRows / newPageSize);
    if (newPageIndex < 1) newPageIndex = 1
    else if(newPageIndex > newPageCount) newPageIndex = newPageCount;

    await this.setStateAsync({ pageSize: newPageSize, pageCount: newPageCount, pageIndex: newPageIndex });
    console.log(`Updated page size to ${this.state.pageSize}, pageCount to ${this.state.pageCount} and pageIndex to ${this.state.pageIndex}`)
    this.loadData();
  }

  /**
   * Updates the column to sort on. Sets the display sort in the state and the sortDirection if there's no change and calls loadData
   * @param {*} displaySort 
   * @param {*} displayHeader 
   * @returns 
   */
  async updateDisplaySort(displaySort, displayHeader) {
    console.log('Sorting by ' + displaySort + ' display header: ' + displayHeader);
    if(!displaySort || displayHeader === 'EWFM' || displayHeader === 'Connect')
      return;

    let sortDirection = 'DESC';
    if (this.state.sortDirection === 'DESC') {
      sortDirection = 'ASC';
    }
    else {
      sortDirection= 'DESC';
    }

    await this.setStateAsync({displaySort, sortDirection});

    this.loadData();
  }

  render() {
    const {
      runningEwfmEtlProcess,
      ewfmEtlProgress,
      ewfmEtlLastUpdateTime,
      runningConnectEtlProcess,
      connectEtlProgress,
      connectEtlLastUpdateTime,
      startEwfmEtlProcess,
      startConnectEtlProcess,
    } = this.props;

    return (
      <>
        <div className={'hierarchy-container' + (this.state.loadingData ? ' loading' : '')}>
          <Filters 
            businessUnits={this.state.businessUnits} 
            routingProfiles={this.state.routingProfiles} 
            connectBusinessUnits={this.state.connectBusinessUnits} 
            connectRoutingProfiles={this.state.connectRoutingProfiles} 
            comparisonCounts={this.state.comparisonCounts} 
            loadFilteredData={
              (businessUnit, routingProfile, connectBusinessUnit, connectRoutingProfile, comparison) => 
                this.loadFilteredData(businessUnit, routingProfile, connectBusinessUnit, connectRoutingProfile, comparison)
            }
            runningEwfmEtlProcess={runningEwfmEtlProcess} 
            ewfmEtlProgress={ewfmEtlProgress} 
            ewfmEtlLastUpdateTime={ewfmEtlLastUpdateTime} 
            startEwfmEtlProcess={() => startEwfmEtlProcess()} 
            runningConnectEtlProcess={runningConnectEtlProcess} 
            connectEtlProgress={connectEtlProgress} 
            connectEtlLastUpdateTime={connectEtlLastUpdateTime} 
            startConnectEtlProcess={() => startConnectEtlProcess()} 
            showEtlButtons={false} 
          />
          <div className="progress-container">
            {
              this.state.syncStatus === 'RUNNING' || this.state.syncStatus === 'INDETERMINATE' ? 
              <LinearProgress
                variant="determinate"
                value={this.state.syncPercentage}
              /> :
              ''
            }
          </div>
          <Table
            columns={this.state.columns} 
            hiddenColumns={this.state.hiddenColumns} 
            data={this.state.data} 
            pageSize={this.state.pageSize} 
            pageIndex={this.state.pageIndex} 
            pageCount={this.state.pageCount} 
            separatorColumn="CONNECT_HIERARCHY_HASH" 
            displaySort={this.state.displaySort} 
            sortDirection={this.state.sortDirection} 
            updatePageIndex={(newPageIndex) => this.updatePageIndex(newPageIndex)} 
            updatePageSize={(newPageSize) => this.updatePageSize(newPageSize)} 
            updateDisplaySort={(displaySort, displayHeader) => this.updateDisplaySort(displaySort, displayHeader)} 
          />
        </div>
      </>
    );
  }
}

