import {useState} from 'react';
import { MenuItem, Select, Checkbox, ListItemText, FormControl, InputLabel, Button, LinearProgress } from '@mui/material';
import '../styles/Filters.css';


function Filters ({ businessUnits, routingProfiles, connectBusinessUnits, connectRoutingProfiles, comparisonCounts, runningEwfmEtlProcess, ewfmEtlProgress, startEwfmEtlProcess, ewfmEtlLastUpdateTime, runningConnectEtlProcess, connectEtlProgress, connectEtlLastUpdateTime, startConnectEtlProcess, loadFilteredData, showEtlButtons }) {
  let comparisons = [
    { id: "1", text: "Matching in EWFM and Connect", count: comparisonCounts && comparisonCounts["1"] ? comparisonCounts["1"] : "0"},
    { id: "2", text: "Not Matching in EWFM and Connect", count: comparisonCounts && comparisonCounts["2"] ? comparisonCounts["2"] : "0"},
    { id: "3", text: "Exist in Connect not in EWFM", count: comparisonCounts && comparisonCounts["3"] ? comparisonCounts["3"] : "0"},
    { id: "4", text: "Exist in EWFM not in Connect", count: comparisonCounts && comparisonCounts["4"] ? comparisonCounts["4"] : "0"}
  ];

  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([]);
  const [selectedRoutingProfiles, setSelectedRoutingProfiles] = useState([]);
  const [selectedComparisons, setSelectedComparisons] = useState([]);
  const [selectedConnectBusinessUnits, setSelectedConnectBusinessUnits] = useState([]);
  const [selectedConnectRoutingProfiles, setSelectedConnectRoutingProfiles] = useState([]);


  const onBusinessUnitChanged = (values) => {
    console.log(`EWFM Business Unit changed to ${values}`);

    if(values.indexOf("All") > -1 && selectedBusinessUnits.length < businessUnits.length){
      setSelectedBusinessUnits(businessUnits.map(businessUnit => businessUnit.BUSINESS_UNIT));
      values = ["All"];
    }
    else if(values.indexOf("All") > -1){
      setSelectedBusinessUnits([]);
      values = ["All"];
    }
    else if(values.length === businessUnits.length){
      setSelectedBusinessUnits(values);
      values = ["All"];
    }
    else
      setSelectedBusinessUnits(values);

    filterData({businessUnit: values});
  }

  const onRoutingProfileChanged = (values) => {
    console.log(`EWFM Routing Profile changed to ${values}`);


    if(values.indexOf("All") > -1 && selectedRoutingProfiles.length < routingProfiles.length){
      setSelectedRoutingProfiles(routingProfiles.map(routingProfile => routingProfile.ROUTING_PROFILE));
      values = ["All"];
    }
    else if(values.indexOf("All") > -1){
      setSelectedRoutingProfiles([]);
      values = ["All"];
    }
    else if(values.length === routingProfiles.length){
      setSelectedRoutingProfiles(values);
      values = ["All"];
    }
    else
      setSelectedRoutingProfiles(values);

    filterData({routingProfile: values});
  }

  const onConnectBusinessUnitChanged = (values) => {
    console.log(`Connect Business Unit changed to ${values}`);

    if(values.indexOf("All") > -1 && selectedConnectBusinessUnits.length < connectBusinessUnits.length){
      setSelectedConnectBusinessUnits(connectBusinessUnits.map(businessUnit => businessUnit.BUSINESS_UNIT));
      values = ["All"];
    }
    else if(values.indexOf("All") > -1){
      setSelectedConnectBusinessUnits([]);
      values = ["All"];
    }
    else if(values.length === connectBusinessUnits.length){
      setSelectedConnectBusinessUnits(values);
      values = ["All"];
    }
    else
      setSelectedConnectBusinessUnits(values);

    filterData({connectBusinessUnit: values});
  }

  const onConnectRoutingProfilesChanged = (values) => {
    console.log(`Connect Routing Profile changed to ${values}`);

    if(values.indexOf("All") > -1 && selectedConnectRoutingProfiles.length < connectRoutingProfiles.length){
      setSelectedConnectRoutingProfiles(connectRoutingProfiles.map(routingProfile => routingProfile.ROUTING_PROFILE));
      values = ["All"];
    }
    else if(values.indexOf("All") > -1){
      setSelectedConnectRoutingProfiles([]);
      values = ["All"];
    }
    else if(values.length === connectRoutingProfiles.length){
      setSelectedConnectRoutingProfiles(values);
      values = ["All"];
    }
    else
      setSelectedConnectRoutingProfiles(values);

    filterData({connectRoutingProfile: values});
  }

  const onComparisionChanged = (values) => {
    console.log(`Comparision changed to ${values}`);

    if(values.indexOf("All") > -1 && selectedComparisons.length < comparisons.length) {
      setSelectedComparisons(comparisons.map(comp => comp.id));
      values = ["All"];
    }
    else if(values.indexOf("All") > -1 || values.length === 0) {
      setSelectedComparisons([]);
      values = ["All"];
    }
    else if(values.length === comparisons.length){
      setSelectedComparisons(values);
      values = ["All"];
    }
    else {
      console.log("Adding comparision " + JSON.stringify(values));
      setSelectedComparisons(values);
    }

    filterData({comparison: values});
  }

  const filterData = ({
    businessUnit = selectedBusinessUnits, 
    routingProfile = selectedRoutingProfiles, 
    connectBusinessUnit = selectedConnectBusinessUnits,
    connectRoutingProfile = selectedConnectRoutingProfiles,
    comparison = selectedComparisons, 
  }) => {
    console.log(`Searching on ewfm businessUnit: ${businessUnit}, \newfm routingProfile: ${routingProfile}, \nconnectBusinessUnit: ${connectBusinessUnit}, \nconnectRoutingProfile: ${connectRoutingProfile}, \ncomparison: ${comparison}`);

    if(businessUnit.length === businessUnits.length)
      businessUnit = ["All"];
    if(routingProfile.length === routingProfiles.length)
      routingProfile = ["All"];
    if(connectBusinessUnit.length === connectBusinessUnits.length)
      connectBusinessUnit = ["All"];
    if(connectRoutingProfile.length === connectRoutingProfiles.length)
      connectRoutingProfile  = ["All"];

    loadFilteredData(businessUnit.join(",") || "All", routingProfile.join(",") || "All", connectBusinessUnit.join(",") || "All", connectRoutingProfile.join(",") || "All", comparison.join(",") || "All");
  }

  const renderRoutingProfiles = (selectedValues) => {
    if(selectedValues.length === 1) {
      //Only one selection and not 'All' so display value
      return selectedValues[0];
    }
    else if(routingProfiles.length === selectedValues.length) {
      return "All";
    }
    else {
      return "Custom";
    }
  }

  const renderBusinessUnits = (selectedValues) => {
    if(selectedValues.length === 1) {
      //Only one selection and not 'All' so display value
      return selectedValues[0];
    }
    else if(businessUnits.length === selectedValues.length) {
      return "All";
    }
    else {
      return "Custom";
    }
  }

  const renderConnectRoutingProfiles = (selectedValues) => {
    if(selectedValues.length === 1) {
      //Only one selection and not 'All' so display value
      return selectedValues[0];
    }
    else if(connectRoutingProfiles.length === selectedValues.length) {
      return "All";
    }
    else {
      return "Custom";
    }
  }

  const renderConnectBusinessUnits = (selectedValues) => {
    if(selectedValues.length === 1) {
      //Only one selection and not 'All' so display value
      return selectedValues[0];
    }
    else if(connectBusinessUnits.length === selectedValues.length) {
      return "All";
    }
    else {
      return "Custom";
    }
  }

  const renderComparisonValues = (selectedValues) => {
    if(selectedValues.length === 1 && selectedValues.indexOf('All') === -1) {
      //Only one selection and not 'All' so display value
      return comparisons.filter((comp) => comp.id === selectedValues[0])[0].text;
    }
    else if((selectedValues.indexOf("All") > -1 && selectedComparisons.length < comparisons.length) || 
      (selectedValues.indexOf("All") === -1 && selectedComparisons.length === comparisons.length) || 
      selectedValues.length === 0) {
      return "All";
    }
    else {
      return "Custom";
    }
  }

  return (
    <>
      <div className='filters-container'>
          <div className="filters-column">
            <div className="filters-row">
              <div className="select-container">
                <FormControl fullWidth={true} variant='standard'>
                  <InputLabel id="business-units-label">EWFM Business Unit: </InputLabel>
                  <Select
                    labelId="business-units-label"
                    id="business-units"
                    name="business-units"
                    multiple
                    value={selectedBusinessUnits}
                    onChange={(e) => onBusinessUnitChanged(e.target.value)}
                    renderValue={(selected) => renderBusinessUnits(selected)}
                    label="EWFM Business Unit: "
                  >
                    <MenuItem key="-1" value="All">
                      <Checkbox checked={(selectedBusinessUnits.length === businessUnits.length) || (selectedBusinessUnits.length === 0)} />
                      <ListItemText primary="All" />
                    </MenuItem>
                    {
                      businessUnits.map((businessUnit, i) => (
                        <MenuItem key={i} value={businessUnit.BUSINESS_UNIT}>
                          <Checkbox checked={selectedBusinessUnits.indexOf(businessUnit.BUSINESS_UNIT) > -1} />
                          <ListItemText primary={businessUnit.BUSINESS_UNIT} />
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="select-container">
                <FormControl fullWidth={true} variant='standard'>
                  <InputLabel id="routing-profiles-label">EWFM Routing Profile: </InputLabel>
                  <Select
                    labelId="routing-profiles-label"
                    id="routing-profiles"
                    name="routing-profiles"
                    multiple
                    value={selectedRoutingProfiles}
                    onChange={(e) => onRoutingProfileChanged(e.target.value)}
                    renderValue={(selected) => renderRoutingProfiles(selected)}
                    label="EWFM Routing Profile: "
                  >
                    <MenuItem key="-1" value="All">
                      <Checkbox checked={(selectedRoutingProfiles.length === routingProfiles.length) || (selectedRoutingProfiles.length === 0)} />
                      <ListItemText primary="All" />
                    </MenuItem>
                    {
                      routingProfiles.map((routingProfile, i) => (
                        <MenuItem key={i} value={routingProfile.ROUTING_PROFILE}>
                          <Checkbox checked={selectedRoutingProfiles.indexOf(routingProfile.ROUTING_PROFILE) > -1} />
                          <ListItemText primary={routingProfile.ROUTING_PROFILE} />
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="select-container">
                <FormControl fullWidth={true} variant='standard'>
                  <InputLabel id="comparisons-label">Filter: </InputLabel>
                  <Select
                    labelId="comparisons-label"
                    id="comparisons"
                    name="comparisons"
                    multiple
                    value={selectedComparisons}
                    onChange={(e) => onComparisionChanged(e.target.value)}
                    renderValue={(selected) => renderComparisonValues(selected)}
                    label="Filter: "
                  >
                    <MenuItem key="-1" value={"All"}>
                      <Checkbox checked={(selectedComparisons.length === comparisons.length) || selectedComparisons.length === 0} />
                      <ListItemText primary="All" secondary={comparisonCounts && comparisonCounts["All"] ? comparisonCounts["All"] : "0"} />
                    </MenuItem>
                    {
                      comparisons.map((comparison, i) => (
                        <MenuItem key={i} value={comparison.id}>
                          <Checkbox checked={(selectedComparisons.findIndex(selectedComparison => selectedComparison === comparison.id) > -1) || (selectedComparisons[0] === "All")} />
                          <ListItemText primary={comparison.text} secondary={comparison.count} />
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="filters-row">
              <div className="select-container">
                <FormControl fullWidth={true} variant='standard'>
                  <InputLabel id="connect-business-units-label">Connect Business Unit: </InputLabel>
                  <Select
                    labelId="connect-business-units-label"
                    id="connect-business-units"
                    name="connect-business-units"
                    multiple
                    value={selectedConnectBusinessUnits}
                    onChange={(e) => onConnectBusinessUnitChanged(e.target.value)}
                    renderValue={(selected) => renderConnectBusinessUnits(selected)}
                    label="Connect Business Unit: "
                  >
                    <MenuItem key="-1" value="All">
                      <Checkbox checked={(selectedConnectBusinessUnits.length === connectBusinessUnits.length) || (selectedConnectBusinessUnits.length === 0)} />
                      <ListItemText primary="All" />
                    </MenuItem>
                    {
                      connectBusinessUnits.map((businessUnit, i) => (
                        <MenuItem key={i} value={businessUnit.BUSINESS_UNIT}>
                          <Checkbox checked={selectedConnectBusinessUnits.indexOf(businessUnit.BUSINESS_UNIT) > -1} />
                          <ListItemText primary={businessUnit.BUSINESS_UNIT} />
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="select-container">
                <FormControl fullWidth={true} variant='standard'>
                  <InputLabel id="routing-profiles-label">Connect Routing Profile: </InputLabel>
                  <Select
                    labelId="routing-profiles-label"
                    id="routing-profiles"
                    name="routing-profiles"
                    multiple
                    value={selectedConnectRoutingProfiles}
                    onChange={(e) => onConnectRoutingProfilesChanged(e.target.value)}
                    renderValue={(selected) => renderConnectRoutingProfiles(selected)}
                    label="Connect Routing Profile: "
                  >
                    <MenuItem key="-1" value="All">
                      <Checkbox checked={(selectedConnectRoutingProfiles.length === connectRoutingProfiles.length) || (selectedConnectRoutingProfiles.length === 0)} />
                      <ListItemText primary="All" />
                    </MenuItem>
                    {
                      connectRoutingProfiles.map((routingProfile, i) => (
                        <MenuItem key={i} value={routingProfile.ROUTING_PROFILE}>
                          <Checkbox checked={selectedConnectRoutingProfiles.indexOf(routingProfile.ROUTING_PROFILE) > -1} />
                          <ListItemText primary={routingProfile.ROUTING_PROFILE} />
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className={"filters-column right" + (showEtlButtons ? "" : " hidden")}>
            <div>
              <div className="filters-row end">
                <label htmlFor="">EWFM Last Update {ewfmEtlLastUpdateTime && typeof(ewfmEtlLastUpdateTime.format) === 'function' ? ewfmEtlLastUpdateTime.format('YYYY-MM-DD HH:mm:ss') : ''}</label>
                <Button variant="outlined" onClick={() => startEwfmEtlProcess()} disabled={runningEwfmEtlProcess}>LOAD</Button>
              </div>
              <div className="progress-container">
                {
                  runningEwfmEtlProcess === true ?
                  <LinearProgress
                    variant="determinate"
                    value={ewfmEtlProgress ? ewfmEtlProgress : 0}
                  /> :
                  ''
                }
              </div>
            </div>
            <div>
              <div className="filters-row end">
                <label htmlFor="">Connect Last Update {connectEtlLastUpdateTime &&typeof(connectEtlLastUpdateTime.format) === 'function' ? connectEtlLastUpdateTime.format('YYYY-MM-DD HH:mm:ss') : ''}</label>
                <Button variant="outlined" onClick={() => startConnectEtlProcess()} disabled={runningConnectEtlProcess}>LOAD</Button>
              </div>
              <div className="progress-container">
                {
                  runningConnectEtlProcess === true ?
                  <LinearProgress
                    variant="determinate"
                    value={connectEtlProgress ? connectEtlProgress : 0}
                  /> :
                  ''
                }
              </div>
            </div>
          </div>

      </div>
    </>
  );
}

export default Filters;