export const URLS = {
  LOAD_BUSINESS_UNITS_AND_ROUTING_PROFILES_URL: "https://89qo9cdn77.execute-api.eu-west-2.amazonaws.com/prod/business_units_and_routing_profiles",
  LOAD_AGENT_DATA_URL:                          "https://89qo9cdn77.execute-api.eu-west-2.amazonaws.com/prod/employees",
  LOAD_HIERARCHY_DATA_URL:                      "https://89qo9cdn77.execute-api.eu-west-2.amazonaws.com/prod/hierarchy",
  VERIFY_USER_URL:                              "https://89qo9cdn77.execute-api.eu-west-2.amazonaws.com/prod/config_sync_user_type",
  START_EWFM_ETL_URL:                           "https://89qo9cdn77.execute-api.eu-west-2.amazonaws.com/prod/run_ewfm_on_prem_etl_process",
  MONITOR_EWFM_ETL_URL:                         "https://89qo9cdn77.execute-api.eu-west-2.amazonaws.com/prod/status_ewfm_on_prem_etl_process",
  LOAD_ROUTING_PROFILES_URL:                    "https://89qo9cdn77.execute-api.eu-west-2.amazonaws.com/prod/routingprofiles",
  CONFIG_SYNC_VERSIONS_URL:                     "https://89qo9cdn77.execute-api.eu-west-2.amazonaws.com/prod/versions?resource=ConfigSyncSite&version=2",
  WHITELIST_USERS_URL:                          "https://89qo9cdn77.execute-api.eu-west-2.amazonaws.com/prod/whitelist_users",
  EXPORT_URL:                                   "https://89qo9cdn77.execute-api.eu-west-2.amazonaws.com/prod/get_export_url",
  MANUAL_SSO_LOGIN_REDIRECT_URL:                "https://configsync-prod.auth.eu-west-2.amazoncognito.com/oauth2/authorize?identity_provider=Centrica-AD-Provider&redirect_uri=https://config-sync-prod.centrica.com&response_type=token&client_id=7hso34s10v0hhunbdr43k8a894&scope=aws.cognito.signin.user.admin email openid",
  LIST_AGENT_SYNC_URL:                          "https://s77xrtqm1g.execute-api.eu-west-2.amazonaws.com/live/sync_agents/list",
  START_AGENT_SYNC_URL:                         "https://s77xrtqm1g.execute-api.eu-west-2.amazonaws.com/live/sync_agents/start",
  MONITOR_AGENT_SYNC_URL:                       "https://s77xrtqm1g.execute-api.eu-west-2.amazonaws.com/live/sync_agents/monitor",
  INTERRUPT_AGENT_SYNC_URL:                     "https://s77xrtqm1g.execute-api.eu-west-2.amazonaws.com/live/sync_agents/interrupt",
  INITIAL_CONNECT_ETL_URL:                      "https://s77xrtqm1g.execute-api.eu-west-2.amazonaws.com/live/etl/startup",
  START_CONNECT_ETL_URL:                        "https://s77xrtqm1g.execute-api.eu-west-2.amazonaws.com/live/etl/start",
  MONITOR_CONNECT_ETL_URL:                      "https://s77xrtqm1g.execute-api.eu-west-2.amazonaws.com/live/etl/monitor",
};

export const API_KEYS = {
  CONFIG_SYNC_VERSIONS_KEY:                     "QWZpwqu4Z58x6plm1Uxg77gK1hmXuaO06173Q82L",
  VERIFY_USER_KEY:                              "fvymSUUehS2H0g9mjAAyZQKMfySXqxrS1I9SiB50",
  START_EWFM_ETL_PROCCESS_KEY:                  "WVP0UsXFSw5c1b7Hh5wXC9vJQO2cDxyi56TqQZ77",
  MONITOR_EWFM_ETL_PROCCESS_KEY:                "W8NiCQO6o65mJF99asD573rly2X1Gi2k28gaYFQ9",
  LOAD_AGENT_DATA_KEY:                          "cMYHLhlAFc96B3Q0tCr613UJl0htEl6N7ni3fKDE",
  LOAD_BUSINESS_UNITS_AND_ROUTING_PROFILES_KEY: "fiiuZTQVRd3iAG9E6rgYa7ZgYxeLyBd87dcWLLZW",
  LOAD_HIERARCHY_DATA_KEY:                      "9yTSXwfCeD7EGkYH2BLeG4kjZ1sPm5J92vg7PsXe",
  LOAD_ROUTING_PROFILES_KEY:                    "kTqfyqOlvGaCZ1CrgW6lsaVdv57ks0dC8kQJULj6",
  WHITELIST_USERS_KEY:                          "LQu1jhxZK0a7eQvK1vFWGatCPFxFyEih8TMJWlF6",
  EXPORT_KEY:                                   "LQu1jhxZK0a7eQvK1vFWGatCPFxFyEih8TMJWlF6"
};

export const API_BODY = {
  INITIAL_CONNECT_ETL_BODY: {
    "arn": "arn:aws:states:eu-west-2:493470264476:stateMachine:wc_configSync_connect_etl"
  },
  START_CONNECT_ETL_BODY: {
    "instanceAttributes" : {
      "instanceId": "aee4fe06-de62-4098-b27e-937a6b21b2b6", //prod instance
      "roleARN": "arn:aws:iam::493470264476:role/WcConnectAccessFromDev",
      "useProdInstance": false
    },
    "arn": "arn:aws:states:eu-west-2:493470264476:stateMachine:wc_configSync_connect_etl"
  },
  START_AGENT_SYNC_BODY: {
    "instanceAttributes" : {
      "instanceId": "aee4fe06-de62-4098-b27e-937a6b21b2b6", //prod instance
      "useProdInstance": false
    },
    "arn": "arn:aws:states:eu-west-2:493470264476:stateMachine:wc_configSync_syncAgents",
  },
};