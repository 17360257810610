import {useState, useEffect} from 'react';
import axios from 'axios';
import { URLS, API_KEYS } from '../configs/apis.js';
import{ useSortBy, useTable, useFilters } from 'react-table';
import { Button, FormControl, TextField, InputLabel, IconButton, MenuItem, Select, FormGroup, Switch, FormControlLabel} from '@mui/material';
import { FirstPage, NavigateBefore, NavigateNext, LastPage } from '@mui/icons-material';
import '../styles/UserWhitelist.css';

function  UserWhitelist () {
  const [ whitelistedUsers, setWhitelistedUsers ] = useState([{
    "ROWNUMBER": "-1",
    "USERNAME": "-",
    "USER_TYPE": "-",
    "PRESERVE_USER_FLAG": "-",
    "FIRSTNAME": "-",
    "LASTNAME": "-",
  }]);
  const columns = [
    {
      Header: 'Username',
      accessor: 'USERNAME',
    },
    {
      Header: 'First Name',
      accessor: 'FIRSTNAME',
    },
    {
      Header: 'Last Name',
      accessor: 'LASTNAME',
    },
    {
      Header: 'User Type',
      accessor: 'USER_TYPE',
    },
    {
      Header: 'Preserve User Flag',
      accessor: 'PRESERVE_USER_FLAG',
    },
    {
      Header: 'Start Date',
      accessor: 'PRESERVE_USER_START_DATE',
    },
    {
      Header: 'End Date',
      accessor: 'PRESERVE_USER_END_DATE',
    },
    {
      Header: 'Row Number',
      accessor: 'ROWNUMBER',
    },
  ];
  const hiddenColumns = [
    'ROWNUMBER',
  ];
  const [displaySort, setDisplaySort] = useState("USERNAME");
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortDirection, setSortDirection] = useState('ASC');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pageCount, setPageCount] = useState(0);


  const [addingUser, setAddingUser] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(false);

  const [newPayrollId, setNewPayrollId] = useState("");
  const [newConfigSyncUser, setNewConfigSyncUser] = useState("N");
  const [newPreserveUserFlag, setNewPreserveUserFlag] = useState("Y");
  const [newPreserveUserStartDate, setNewPreserveUserStartDate] = useState('');
  const [newPreserveUserEndDate, setNewPreserveUserEndDate ] = useState('');

  const [updateConfigSyncUser, setUpdateConfigSyncUser] = useState("N");
  const [updatePreserveUserFlag, setUpdatePreserveUserFlag] = useState("N");
  const [updatePreserveUserStartDate, setUpdatePreserveUserStartDate] = useState('');
  const [updatePreserveUserEndDate, setUpdatePreserveUserEndDate] = useState('');

  const [totalUsers, setTotalUsers] = useState(0);


  /**
   * Called once on first render to load initial data
   */
  useEffect(() => {
    console.log("Get data for new page");
    loadData();
  }, []);

  useEffect(() => {
    console.log("New selected rows: " + JSON.stringify(selectedRows, null, 2));
  }, [selectedRows]);

  /**
   * Adds the row numbers for all displayed data to selected rows array on the state
   */
  const selectAllRows = () => {
    console.log('selectAllRows called');

    if(selectedRows.length < whitelistedUsers.length) {
      setSelectedRows(whitelistedUsers.map((row) => {
        return row.ROWNUMBER;
      }));
    }
    else {
      setSelectedRows([]);
    }

    console.log('selectedRows: ' + JSON.stringify(selectedRows, null, 2));
  };

  
  /**
   * Adds the given row number to the selectedRows array on the state or removes if already there
   * @param {*} rowNumber 
   */
   const selectRow = (rowNumber) => {
    console.log('Selected row ' + rowNumber);

    if(selectedRows.indexOf(rowNumber) === -1) {
      console.log("Row not already selected so adding to array");
      setSelectedRows([...selectedRows, rowNumber]);
    }
    else {
      console.log(`Already have row number ${rowNumber} selected so unchecking`);
      setSelectedRows(selectedRows.filter((item) => item !== rowNumber));
    }
  }

  /**
   * Called once on load. Gets the lists of FAs and SGs for drop down selects
   */
  const loadData = (pgIndex = pageIndex, pgSize = pageSize, disSort = displaySort, srtDirection = sortDirection) => {
    console.log(`loading lists of businessUnits and routingProfiles`);

    const config = {
      headers: {
        'x-api-key': API_KEYS.WHITELIST_USERS_KEY,
      }
    };

    pgIndex = Math.round((pgSize * (pgIndex - 1)) + 1);

    const URL = URLS.WHITELIST_USERS_URL + `?startIndex=${pgIndex}&pageSize=${pgSize}&displaySort=${disSort}&displaySortDirection=${srtDirection}`;

    console.log('Now calling URL: ' + URL);
    axios.get(URL, config)
    .then((response) => {
      console.log(response);

      let whitelistUsers = response && response.data && response.data.dataSet ? response.data.dataSet : [];
      let countTotal = response && response.data && response.data.count ? response.data.count : 0;

      //Total used to stop page number going above max
      setTotalUsers(parseInt(countTotal, 10));

      let newPageCount = Math.ceil(countTotal / pgSize);

      //Get only date part of string produced by select statement
      whitelistUsers = whitelistUsers.map((whitelistUser) => {
        whitelistUser.PRESERVE_USER_START_DATE = whitelistUser.PRESERVE_USER_START_DATE ? whitelistUser.PRESERVE_USER_START_DATE.split('T')[0] : '';
        whitelistUser.PRESERVE_USER_END_DATE = whitelistUser.PRESERVE_USER_END_DATE ? whitelistUser.PRESERVE_USER_END_DATE.split('T')[0] : '';
        return whitelistUser;
      })

      console.log(`whitelistUsers: ${JSON.stringify(whitelistUsers, null, 2)}, newPageCount: ${newPageCount}`);

      setWhitelistedUsers(whitelistUsers);
      setPageCount(newPageCount);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  /**
   * Updates the page index/number in the state and calls loadData to get the data for the new page
   * @param {*} newPageIndex 
   */
  const updatePageIndex = (newPageIndex) => {
    //Clear selected rows
    setSelectedRows([]);

    //Set the index to the new page
    setPageIndex(newPageIndex);

    //Load the data for the new page
    loadData(newPageIndex);
  }

  /**
   * Calculates the new page index for the new page size, updates both in the state and calls loadData
   * @param {*} newPageSize 
   */
  const updatePageSize = async (newPageSize) => {
    console.log('Called updatePageSize with newPageSize: ' + newPageSize.toString());
    let newPageIndex = Math.floor((pageSize / newPageSize) * pageIndex);

    let newPageCount = Math.ceil(totalUsers / newPageSize);
    if (newPageIndex < 1) newPageIndex = 1
    else if(newPageIndex > newPageCount) newPageIndex = newPageCount;

    setSelectedRows([]);
    setPageCount(newPageCount);
    setPageSize(newPageSize);
    setPageIndex(newPageIndex);
    console.log(`Updated page size to ${newPageSize} and pageIndex to ${newPageIndex}`);
    loadData(newPageIndex, newPageSize);
  }

  /**
   * Updates the column to sort on. Sets the display sort in the state and the sortDirection if there's no change and calls loadData
   * @param {*} displaySort 
   * @param {*} displayHeader 
   * @returns 
   */
  const updateDisplaySort = async (displaySort, displayHeader) => {
    console.log('Sorting by ' + displaySort + ' display header: ' + displayHeader);

    setDisplaySort(displaySort);

    if (sortDirection === 'DESC') {
      setSortDirection('ASC');
      loadData(pageIndex, pageSize, displaySort, 'ASC');
    }
    else {
      setSortDirection('DESC');
      loadData(pageIndex, pageSize, displaySort, 'DESC');
    }
  }

  const handleAddUserClick = () => {
    setAddingUser(true);
  }

  const handleUpdateUserClick = () => {
    const userRow = whitelistedUsers.find((whitelistedUser) => whitelistedUser.ROWNUMBER === selectedRows[0]);

    console.log(`Found selected user row ${JSON.stringify(userRow)}`);
    if(!userRow){
      console.error(`Unable to get user details by row number`);
    }
    else {
      if(userRow.USER_TYPE === "Admin")
        setUpdateConfigSyncUser("Y");
      else
        setUpdateConfigSyncUser("N");

      setUpdatePreserveUserFlag(userRow.PRESERVE_USER_FLAG);
    }

    setUpdatingUser(!updatingUser);
    return;
  }

  const handleDeleteUserClick = () => {
    if(selectedRows.length <= 0) {
      window.alert("Unable to delete as no rows selected.");
      return;
    }

    let confirmMessage = "";
    if(selectedRows.length === 1)
      confirmMessage += "Are you sure you want to delete the user ";
    else
      confirmMessage += "Are you sure you want to delete the users ";

    let usersToBeDeleted = whitelistedUsers.filter((user) => selectedRows.includes(user.ROWNUMBER)).map(user => user.USERNAME);
    
    confirmMessage = confirmMessage + usersToBeDeleted.join(" ,") + "?";

    if(window.confirm(confirmMessage)) {
      console.log(`Deleting users ${usersToBeDeleted.join(" ,")}`);

      const config = {
        headers: {
          'x-api-key': API_KEYS.WHITELIST_USERS_KEY,
        }
      };

      const URL = URLS.WHITELIST_USERS_URL + `?displaySort=${displaySort}&displaySortDirection=${sortDirection}&rowNumbers=${selectedRows.join(",")}`;

      console.log('Now calling URL: ' + URL);
      axios.delete(URL, config)
      .then((response) => {
        console.log(response);

        let deletedUsers = response && response.data && response.data.dataSet ? response.data.dataSet : [];
        console.log("deletedUsers: " + deletedUsers);

        let message = "";
        if(deletedUsers.length <= 0) {
          message = "Unable to delete users.";
        }
        else if(deletedUsers.length === 1) {
          message = "Deleted the user " + deletedUsers[0].PAYROLL + ".";
        }
        else {
          message = "Deleted the following users " + deletedUsers.map(user => user.PAYROLL).join(", ") + ".";
        }

        setSelectedRows([]);
        alert(message);

        loadData();
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

  const addNewUser = (e) => {
    console.log(`Adding new user ${newPayrollId}, ${newConfigSyncUser}, ${newPreserveUserFlag}`);

    let newUserType = "Basic User";
    if(newConfigSyncUser === 'Y')
      newUserType = "Admin";

      var body = {
        payroll: newPayrollId,
        userType: newUserType,
      };

    if(newPreserveUserFlag === 'Y') {
      body.preserveUserStartDate = newPreserveUserStartDate;
      body.preserveUserEndDate = newPreserveUserEndDate;
    }

    const config = {
      headers: {
        'x-api-key': API_KEYS.WHITELIST_USERS_KEY,
      }
    };

    const URL = URLS.WHITELIST_USERS_URL;

    console.log('Now calling URL: ' + URL);
    axios.post(URL, body, config)
    .then((response) => {
      console.log(response);

      let addedUser = response && response.data && response.data.addedUser ? response.data.addedUser : -1;
      console.log("addedUser: " + addedUser);

      let message = "";
      if(addedUser <= 0) {
        message = "Unable to add user.";
      }
      else if(addedUser === 1) {
        message = "Added user to Whitelist";
      }
      else if (addedUser === 2) {
        message = "Updated user in Whitelist";
      }

      alert(message);

      loadData();
    })
    .catch((err) => {
      console.log(err);
    });

    setAddingUser(false);
  }

  const updateUsers = (e) => {
    console.log(`Updating users: ${JSON.stringify(selectedRows)}`);

    var userType = "Basic User";
    if(updateConfigSyncUser === 'Y')
      userType = "Admin";

    const body = {
      displaySort: displaySort,
      sortDirection: sortDirection,
      userType: userType,
      rowNumbers: selectedRows,
    };

    const config = {
      headers: {
        'x-api-key': API_KEYS.WHITELIST_USERS_KEY,
        'Content-Type': 'application/json'
      }
    };

    if(updatePreserveUserFlag === 'Y') {
      body.preserveUserStartDate = updatePreserveUserStartDate;
      body.preserveUserEndDate = updatePreserveUserEndDate;
    }

    const URL = URLS.WHITELIST_USERS_URL;

    console.log('Now calling URL: ' + URL);
    axios.put(URL, body, config)
    .then((response) => {
      console.log(response);

      let updatedUsers = response && response.data && response.data.dataSet ? response.data.dataSet : [];
      console.log("Updated Users: " + JSON.stringify(updatedUsers));

      let message = "";
      if(updatedUsers.length <= 0) {
        message = "Unable to update users.";
      }
      else if(updatedUsers.length === 1) {
        message = "Updated the user " + updatedUsers[0].PAYROLL + ".";
      }
      else {
        message = "Updated the following users " + updatedUsers.map(user => user.PAYROLL).join(", ") + ".";
      }

      setSelectedRows([]);
      alert(message);

      loadData();
    })
    .catch((err) => {
      console.log(err);
    });

    setUpdatingUser(false);
  }


  return (
    <>
      <div className="whitelist-container">
        <div className="flex-row">
          <Button
            variant="contained"
            id="add-user-button"
            className="whitelist-button"
            disabled={addingUser || updatingUser}
            onClick={() => handleAddUserClick()}
            disableElevation
          >Add New User</Button>
          <Button
            variant="outlined"
            id="update-user-button"
            className="whitelist-button"
            onClick={() => handleUpdateUserClick()}
            disabled={addingUser || updatingUser || selectedRows.length <= 0}
            disableElevation
          >Update User</Button>
          <Button
            variant="outlined"
            id="delete-users-button"
            className="whitelist-button"
            onClick={() => handleDeleteUserClick()}
            disabled={addingUser || updatingUser || selectedRows.length === 0}
            disableElevation
            color="error"
          >Delete Users</Button>
        </div>
        {
          addingUser ?
          <div className='flex-row'>
            <TextField 
              id='payroll-input' 
              label="Payroll Id:" 
              onChange={(e)=>setNewPayrollId(e.target.value)} 
            />
            <FormGroup id="preserve-user-switch">
              <FormControlLabel 
                label="Config sync User:" 
                labelPlacement="start" 
                control={
                  <Switch 
                    checked={newConfigSyncUser === "Y"} 
                    onChange={(e) => setNewConfigSyncUser(e.target.checked ? "Y" : "N")} 
                  />
                } 
              />
            </FormGroup>
            <FormGroup id="preserve-user-switch">
              <FormControlLabel 
                label="Whitelist User:" 
                labelPlacement="start" 
                control={
                  <Switch 
                    checked={newPreserveUserFlag === "Y"} 
                    onChange={(e) => setNewPreserveUserFlag(e.target.checked ? "Y" : "N")} 
                  />
                }
              />
            </FormGroup>
            {
              newPreserveUserFlag === 'Y' ?
              <div className='flex-row'>
                <FormGroup id="new-preserve-start-date-form">
                <FormControlLabel
                  label="Start Date: "
                  labelPlacement="start"
                  control={
                    <input
                      type="date"
                      id="new-preserve-start-date-picker"
                      name="new-preserve-start-date-picker"
                      class="date-picker"
                      onChange={(e) => setNewPreserveUserStartDate(e.target.value)}
                    />
                  }
                />
                            </FormGroup>
                            <FormGroup id="new-preserve-end-date-form">
                <FormControlLabel
                  label="End Date: "
                  labelPlacement="start"
                  control={
                    <input
                      type="date"
                      id="new-preserve-end-date-picker"
                      name="new-preserve-end-date-picker"
                      class="date-picker"
                      onChange={(e) => setNewPreserveUserEndDate(e.target.value)}
                    />
                  }
                />
                            </FormGroup>
              </div> :
              null
            }
            <FormControl>
              <Button 
                variant="outlined" 
                disableElevation 
                onClick={() => setAddingUser(false)}
              >Cancel</Button>
            </FormControl>
            <FormControl>
              <Button 
                variant="contained" 
                disableElevation 
                onClick={addNewUser}
              >Add</Button>
            </FormControl>
          </div>
          : <></>
        }
        {
          updatingUser && selectedRows.length > 0 ?
          <div className='flex-row'>
            <FormGroup id="config-sync-user-switch">
              <FormControlLabel 
                label="Config sync User:" 
                labelPlacement="start" 
                control={
                  <Switch 
                    checked={updateConfigSyncUser === "Y"} 
                    onChange={(e) => setUpdateConfigSyncUser(e.target.checked ? "Y" : "N")} 
                  />
                } 
              />
            </FormGroup>
            <FormGroup id="preserve-user-switch">
              <FormControlLabel 
                label="Whitelist User:" 
                labelPlacement="start" 
                control={
                  <Switch 
                    checked={updatePreserveUserFlag === "Y"} 
                    onChange={(e) => setUpdatePreserveUserFlag(e.target.checked ? "Y" : "N")} 
                  />
                } 
              />
            </FormGroup>
            {
              updatePreserveUserFlag === "Y" ? 
              <div className='flex-row'>
                <FormGroup id="update-preserve-start-date-form">
                  <FormControlLabel 
                    label="Start Date: " 
                    labelPlacement="start" 
                    control={
                      <input 
                        type="date"
                        id="update-preserve-start-date-picker"
                        name="update-preserve-start-date-picker"
                        class="date-picker"
                        onChange={(e) => setUpdatePreserveUserStartDate(e.target.value)} 
                      />
                    } 
                  />
                </FormGroup>
                <FormGroup id="update-preserve-end-date-form">
                  <FormControlLabel 
                    label="End Date: " 
                    labelPlacement="start" 
                    control={
                      <input 
                        type="date"
                        id="update-preserve-end-date-picker"
                        name="update-preserve-end-date-picker"
                        class="date-picker"
                        onChange={(e) => setUpdatePreserveUserEndDate(e.target.value)} 
                      />
                    } 
                  />
                </FormGroup>
              </div> :
              null
            }
            <FormControl>
              <Button 
                variant="outlined" 
                disableElevation 
                onClick={() => setUpdatingUser(false)}
              >Cancel</Button>
            </FormControl>
            <FormControl>
              <Button 
                variant="contained" 
                disableElevation 
                onClick={updateUsers}
              >Update</Button>
            </FormControl>
          </div>
          : <></>
        }
        <div className='flex-row table-container'>
          <WhitelistTable
            columns={columns}
            hiddenColumns={hiddenColumns}
            data={whitelistedUsers}
            pageSize={pageSize}
            pageIndex={pageIndex}
            pageCount={pageCount}
            displaySort={displaySort}
            sortDirection={sortDirection}
            selectedRows={selectedRows}
            selectRow={(rowNumber) => selectRow(rowNumber)}
            selectAllRows={(rowNumber) => selectAllRows(rowNumber)}
            updatePageIndex={(newPageIndex) => updatePageIndex(newPageIndex)}
            updatePageSize={(newPageSize) => updatePageSize(newPageSize)}
            updateDisplaySort={(displaySort, displayHeader) => updateDisplaySort(displaySort, displayHeader)}
          />
        </div>
      </div>
    </>
  );
}

function WhitelistTable ({columns, data, hiddenColumns = ['COMPARISON','ROWNUMBER',], separatorColumn, displaySort, updateDisplaySort, sortDirection, selectedRows, selectRow, selectAllRows, pageIndex, pageSize, pageCount, updatePageIndex, updatePageSize}) {
  const {
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    prepareRow, 
    rows, 
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns
      },
    }, 
    useFilters,
    useSortBy,
  );


  return (
    <>
      <div className='table-container'>
        <table {...getTableProps()} border="1">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  selectedRows ? 
                  <th>
                    {
                      headerGroup.headers.findIndex((column) => {
                        if (column.Header.toString() === 'EWFM' || column.Header.toString() === 'Connect') {
                          return true;
                        }
                        else {
                          return false;
                        }
                      }) > -1 ? 
                      undefined : 
                        <input 
                          type="checkbox" 
                          ref={(input) => { 
                            if (input) { 
                              input.indeterminate = (selectedRows && selectedRows.length > 0 && selectedRows.length < data.length);
                            }
                          }}
                          checked={selectedRows && selectedRows.length === rows.length} 
                          onChange={() => selectAllRows()} 
                        />
                    }
                  </th> : undefined
                }
                {headerGroup.headers.map((column) => (
                  <th 
                    {...column.getHeaderProps([
                      column.id === separatorColumn || column.Header === 'Connect' ? { className: 'connect-border' } : {},
                      { onClick: () => updateDisplaySort(column.id, column.Header) },
                    ])}
                  >
                    {column.Header} {column.id === displaySort ? (sortDirection === 'DESC' ? '▼': '▲') : ''}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {
                    selectedRows ?
                    <td>
                      <input type="checkbox" checked={selectedRows ? (selectedRows.indexOf(row.values.ROWNUMBER) !== -1) : false} onChange={(e) => selectRow(row.values.ROWNUMBER)} />
                    </td> :
                    undefined
                  }
                  {row.cells.map((cell) => {
                    var hierarcyString = `EWFM Hierarcy: ${cell.row.values.EWFM_HIERARCHY_HASH}, \r\nConnect Hierarchy: ${cell.row.values.CONNECT_HIERARCHY_HASH}`;
                    return (
                    <td 
                      {...cell.getCellProps([
                        cell.column.id === separatorColumn ? { className: 'connect-border' } : {},
                        {
                          title: cell.row.values.EWFM_USERNAME || row.values.CONNECT_USERNAME ? hierarcyString : ""
                        }
                      ])} 
                    >
                      {cell.render("Cell")}
                    </td>);
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className='pagnation'>
          <div className='page-buttons-container'>
            <IconButton onClick={() => updatePageIndex(1)} disabled={!(pageIndex && (pageIndex > 1))} className='page-button'>
              <FirstPage />
            </IconButton>
            <IconButton onClick={() => updatePageIndex(pageIndex - 1)} disabled={!(pageIndex && (pageIndex > 1))} className='page-button'>
              <NavigateBefore />
            </IconButton>
            <IconButton onClick={() => updatePageIndex(pageIndex + 1)} disabled={!(pageIndex && (pageIndex < pageCount))} className='page-button'>
              <NavigateNext/>
            </IconButton>
            <IconButton onClick={() => updatePageIndex(pageCount)} disabled={!(pageIndex && (pageIndex < pageCount))} className='page-button'>
              <LastPage />
            </IconButton>
          </div>
          <span>
            Page{' '}
            <strong>
              <FormControl size="small" variant="standard" >
                <Select
                  id="page-index-select"
                  value={pageIndex ? pageIndex : 1}
                  onChange={e => updatePageIndex(Number(e.target.value))}
                  disabled={!pageIndex}
                >
                  {
                    (
                      () => {
                        var menuItems = [];
                        for(var i = 1; i <= pageCount; i++){
                          menuItems.push(
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          )
                        }
                        return menuItems;
                      }
                    )()
                  }
                </Select>
              </FormControl> of {pageCount}
            </strong>{' '}
          </span>
          <FormControl size="small" variant="standard" >
            <InputLabel id='page-size-select-label'>Page Size</InputLabel>
            <Select
              labelId='page-size-select-label'
              id="page-size-select"
              style={{"minWidth": "80px"}}
              value={pageSize ? pageSize : 50}
              onChange={e => updatePageSize(Number(e.target.value))}
              disabled={!pageSize}
              label='Page Size'
            >
              {[5, 10, 50].map(pageSize => (
                <MenuItem key={pageSize} value={pageSize}>
                  {pageSize}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  )
}

export default UserWhitelist;