import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';

export default class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  onClickTabItem (tab) {
    this.setState({ activeTab: tab });
  }

  render() {
    return (
      <div className="tabs">
        <ol className="tab-list">
          {this.props.children.map((child) => {
            const { label } = child.props;
            return (
              <Tab
                activeTab={this.state.activeTab}
                key={label}
                label={label}
                onClick={(label) => this.onClickTabItem(label)}
              />
            );
          })}
        </ol>
        {this.props.children.map((child) => {
          return (
            <div className={(child.props.label !== this.state.activeTab) ? 'hidden-tab' : 'active-tab'}>
              {child.props.children}
            </div>
          )
        })}
      </div>
    );
  }
}