export const AWS_CONFIG = {
  REGION: "eu-west-2",
  USER_POOL_ID: "eu-west-2_Bqo5nqUXa",
  APP_CLIENT_ID: "7hso34s10v0hhunbdr43k8a894",
  PROVIDER: "Centrica-AD-Provider",
  DOMAIN: "configsync-prod.auth.eu-west-2.amazoncognito.com",
  SCOPE: ["email", "openid"],
  REDIRECT_SIGN_IN: "https://config-sync-prod.centrica.com",
  REDIRECT_SIGN_OUT: "https://config-sync-prod.centrica.com",
  RESPONSE_TYPE: "token",
};